import React from "react";

import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Line,
  LineChart,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export const chartBar = (
  data,
  dataKeyX,
  dataKeyActual,
  dataKeyComparation,
  dataNameActual,
  dataNameComparation,
  tickCount
) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={"100%"}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKeyX} />
        <YAxis tickCount={tickCount} />
        <Tooltip />
        <Bar dataKey={dataKeyActual} name={dataNameActual} fill="#14B8A6" />
        <Bar
          dataKey={dataKeyComparation}
          name={dataNameComparation}
          fill="#3B82F6"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const chartBarOne = (
  data,
  dataKeyX,
  dataKeyActual,
  dataNameActual,
  tickCount
) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={"100%"}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKeyX} />
        <YAxis tickCount={tickCount} />
        <Tooltip />
        <Bar dataKey={dataKeyActual} name={dataNameActual} fill="#14B8A6" />
        
      </BarChart>
    </ResponsiveContainer>
  );
};

export const chartThreeBar = (
  data,
  dataKeyX,
  dataKeyActual,
  dataKeyComparation,
  dataKeyActualComp,
  dataNameActual,
  dataNameActualComp,
  dataNameComparation,
  tickCount,
  content

) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={"100%"}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKeyX} />
        <YAxis tickCount={tickCount} />
        {content ? <Tooltip content={content} /> : <Tooltip />}

        <Bar dataKey={dataKeyActual} name={dataNameActual} fill="#14B8A6" />
        <Bar
          dataKey={dataKeyComparation}
          name={dataNameComparation}
          fill="#3B82F6"
        />
        <Bar
          dataKey={dataKeyActualComp}
          name={dataNameActualComp}
          fill="#acb52d"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const chartLinerDot = (
  data,
  dataKeyX,
  dataKeyActual,
  dataKeyComparation,
  dataNameActual,
  dataNameComparation,
  tickCount
) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        width={"100%"}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKeyX} />
        <YAxis tickCount={tickCount} />
        <Tooltip />
        <Line
          type="monotone"
          dataKey={dataKeyComparation}
          name={dataNameComparation}
          stroke="#14B8A6"
        />
        <Scatter fill="#3B82F6" dataKey={dataKeyActual} name={dataNameActual} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
