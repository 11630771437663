import Services from "api/ProjectApiConsumer";

export function getUsersBoot(obj) {
  return Services.bootService.getUsersBoot(undefined, obj);
}

export function getUsersBootToTraining(obj) {
  return Services.bootService.getUsersBootToTraining(undefined, obj);
}

export function saveAnnotationBoot(obj) {
  return Services.bootService.saveAnnotationBoot(obj);
}
export function saveTrainingBoot(obj) {
  return Services.bootService.saveTrainingBoot(obj);
}
export function openTrainingBoot(obj) {
  return Services.bootService.openTraining(obj);
}
export function closeTraining(id) {
  return Services.bootService.closeTraining(undefined, undefined, id);
}
export function changeTrainingBoot(obj) {
  return Services.bootService.changeTrainingBoot(obj);
}