import React from "react";
import { Tabs } from "antd";
import { FONT } from "constants/index";

import LoadControlClientUnique from "./load-control-unique-client";
import LoadControlClientBoot from "./load-control-boot-client";

const LoadControlClient = () => {
  const items = [
    {
      label: `Método Unique`,
      key: "unique",
      children: <LoadControlClientUnique />,
    },
    {
      label: `Iniciação`,
      key: "boot",
      children: <LoadControlClientBoot />,
    },
  
  ];

  return (
    <Tabs
      defaultActiveKey="1"
      centered
      size="large"
      style={{ fontFamily: FONT }}
      items={items}
    />
  );
};
export default LoadControlClient;
