import React, { useEffect, useState } from "react";

import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { UserRound } from "lucide-react";

import { translationsTypes } from "../planning/columns-table";
const { Text } = Typography;
import { FONT } from "constants/index";
import { changeTrainingBoot } from "services/boot/boot-service";

const ChangeBoot = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [imgAvatar, setImgAvatar] = useState("");
  const [trainings, setTrainings] = useState([]);
  const [formBoot] = Form.useForm();
  const [training, setTraining] = useState({});
  const [historicTraining1, sethistoricTraining1] = useState([]);
  const [historicTraining2, sethistoricTraining2] = useState([]);
  const [historicTraining3, sethistoricTraining3] = useState([]);
  const [historicTraining4, sethistoricTraining4] = useState([]);

  useEffect(() => {
    setLoadingData(true);
    setImgAvatar(props.user.img);
    setTrainings(props.user.allTrainings);
    setTraining(props.user.training);

    setLoadingData(false);
  }, [props]);

  useEffect(() => {
    const exercise1 = trainings.map((item) => {
      return {
        exercise: item.typeExercise1,
        load: item.loadExecuted1,
        week: item.load1,
      };
    });
    const exercise1Accept = [
      {
        exercise: "GOBLET_SQUAT",
        load: exercise1.filter((item) => item.exercise == "GOBLET_SQUAT").pop()?.load,
        week: exercise1.filter((item) => item.exercise == "GOBLET_SQUAT").pop()?.week,
      },
      {
        exercise: "SQUAT_FREE",
        load: exercise1.filter((item) => item.exercise == "SQUAT_FREE").pop()?.load,
        week: exercise1.filter((item) => item.exercise == "SQUAT_FREE").pop()?.week,
      },
      {
        exercise: "FRONT_SQUAT",
        load: exercise1.filter((item) => item.exercise == "FRONT_SQUAT").pop()?.load,
        week: exercise1.filter((item) => item.exercise == "FRONT_SQUAT").pop()?.week,
      },
    ];
    sethistoricTraining1(getMaxLoadByExercise(exercise1Accept));

    const exercise2 = trainings.map((item) => {
      return {
        exercise: item.typeExercise2,
        load: item.loadExecuted2,
        week: item.load2,
      };
    });
    const exercise2Accept = [
      {
        exercise: "HANG_CLEAN",
        load: exercise2.filter((item) => item.exercise == "HANG_CLEAN").pop()?.load,
        week: exercise2.filter((item) => item.exercise == "HANG_CLEAN").pop()?.week,
      },
      {
        exercise: "HANG_CLEAND_2T",
        load: exercise2.filter((item) => item.exercise == "HANG_CLEAND_2T").pop()?.load,
        week: exercise2.filter((item) => item.exercise == "HANG_CLEAND_2T").pop()?.week,
      },
      {
        exercise: "SNATCH",
        load: exercise2.filter((item) => item.exercise == "SNATCH").pop()?.load,
        week: exercise2.filter((item) => item.exercise == "SNATCH").pop()?.week,
      },
    ];
    sethistoricTraining2(getMaxLoadByExercise(exercise2Accept));

    const exercise3 = trainings.map((item) => {
      return {
        exercise: item.typeExercise3,
        load: item.loadExecuted3,
        week: item.load3,
      };
    });
    const exercise3Accept = [
      {
        exercise: "CONTINUOUS_JUMP",
        load: exercise3.filter((item) => item.exercise == "CONTINUOUS_JUMP")
        .pop()?.load,
        week: exercise3.filter((item) => item.exercise == "CONTINUOUS_JUMP")
        .pop()?.week,
      },
      {
        exercise: "DEPTH_JUMP",
        load: exercise3.filter((item) => item.exercise == "DEPTH_JUMP").pop()?.load,
        week: exercise3.filter((item) => item.exercise == "DEPTH_JUMP").pop()?.week,
      },
      {
        exercise: "DROP_JUMP",
        load: exercise3.filter((item) => item.exercise == "DROP_JUMP").pop()?.load,
        week: exercise3.filter((item) => item.exercise == "DROP_JUMP").pop()?.week,
      },
    ];

    sethistoricTraining3(getMaxLoadByExercise(exercise3Accept));

    const exercise4 = trainings.map((item) => {
      return {
        exercise: item.typeExercise4,
        load: item.loadExecuted4,
        week: item.load4,
      };
    });
    let exercise4Accept = [];
    if (training.exercise4 == "HIP_DOMINANT_LEFT") {
      exercise4Accept = [
        {
          exercise: "SLDL_2_DUMBBELLS",
          load: exercise4.filter((item) => item.exercise == "SLDL_2_DUMBBELLS")
          .pop()?.load,
          week: exercise4.filter((item) => item.exercise == "SLDL_2_DUMBBELLS")
          .pop()?.week,
        },
        {
          exercise: "DEADLIFT",
          load: exercise4.filter((item) => item.exercise == "DEADLIFT").pop()?.load,
          week: exercise4.filter((item) => item.exercise == "DEADLIFT").pop()?.week,
        },
        {
          exercise: "ROMANIAN_SURVEY",
          load: exercise4.filter((item) => item.exercise == "ROMANIAN_SURVEY")
          .pop()?.load,
          week: exercise4.filter((item) => item.exercise == "ROMANIAN_SURVEY")
          .pop()?.week,
        },
      ];
    } else {
      exercise4Accept = [
        {
          exercise: "BENCH_BRIDGE_ONE_LEG",
          load: exercise4.filter(
            (item) => item.exercise == "BENCH_BRIDGE_ONE_LEG"
          ).pop()?.load,
          week: exercise4.filter(
            (item) => item.exercise == "BENCH_BRIDGE_ONE_LEG"
          ).pop()?.week,
        },
        {
          exercise: "BENCH_BRIDGE_TWO_LEGS",
          load: exercise4.filter(
            (item) => item.exercise == "BENCH_BRIDGE_TWO_LEGS"
          ).pop()?.load,
          week: exercise4.filter(
            (item) => item.exercise == "BENCH_BRIDGE_TWO_LEGS"
          ).pop()?.week,
        },
      ];
    }
    sethistoricTraining4(getMaxLoadByExercise(exercise4Accept));
  }, [trainings]);

  useEffect(() => {
    formBoot.resetFields();

    formBoot.setFieldsValue({
      exercise1: getTranslationTypeBoot(training.typeExercise1),
      load1: getTranslationTypeBoot(training.loadExecuted1),
      exercise2: getTranslationTypeBoot(training.typeExercise2),
      load2: getTranslationTypeBoot(training.loadExecuted2),
      exercise3: getTranslationTypeBoot(training.typeExercise3),
      load3: getTranslationTypeBoot(training.loadExecuted3),
      exercise4: getTranslationTypeBoot(training.typeExercise4),
      load4: getTranslationTypeBoot(training.loadExecuted4),
      week1: getTranslationTypeBoot(training.load1),
      week2: getTranslationTypeBoot(training.load2),
      week3: getTranslationTypeBoot(training.load3),
      week4: getTranslationTypeBoot(training.load4),
    });
  }, [training]);

  function getTranslationTypeBoot(exercise) {
    return translationsTypes[exercise] ?? exercise;
  }
  const handleSave = () => {
    setLoadingData(true);
    const obj = {
      id: training.id,
      exercise1: formBoot.getFieldValue("newExercise1"),
      load1: formBoot.getFieldValue("newLoad1"),
      exercise2: formBoot.getFieldValue("newExercise2"),
      load2: formBoot.getFieldValue("newLoad2"),
      exercise3: formBoot.getFieldValue("newExercise3"),
      load3: formBoot.getFieldValue("newLoad3"),
      exercise4: formBoot.getFieldValue("newExercise4"),
      load4: formBoot.getFieldValue("newLoad4"),
      week1: formBoot.getFieldValue("newWeek1"),
      week2: formBoot.getFieldValue("newWeek2"),
      week3: formBoot.getFieldValue("newWeek3"),
      week4: formBoot.getFieldValue("newWeek4"),
    };

    changeTrainingBoot(obj)
      .then((res) => {
        setLoadingData(false);
        props.onCloseDrawer();
      })
      .catch((err) => {
        setLoadingData(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  };
  function getMaxLoadByExercise(data) {
    const groupedByExercise = data.reduce((acc, item) => {
      if (!acc[item.exercise]) {
        acc[item.exercise] = [];
      }
      acc[item.exercise].push(item);
      return acc;
    }, {});

    const maxLoadByExercise = Object.entries(groupedByExercise).map(
      ([exercise, items]) => {
        return items.reduce(
          (max, item) => (item.load > max.load ? item : max),
          items[0]
        );
      }
    );

    return maxLoadByExercise;
  }

  function setLoad(e, field, data) {
    const historic = data.find((item) => item.exercise == e);
    formBoot.setFieldValue(['newLoad'+field], historic.load);
    formBoot.setFieldValue(['newWeek'+field], historic.week ?? 1);

  }
  return (
    <>
      <Spin spinning={loadingData}>
        <Row gutter={16} style={{ textAlign: "center", marginBottom: "30px" }}>
          <Col span={24}>
            <Avatar
              src={imgAvatar}
              icon={<UserRound size={120} />}
              size={120}
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ textAlign: "center", marginBottom: "30px" }}>
          <Col span={24}>
            <Text strong style={{ fontFamily: FONT }}>
              {props.user.name}
            </Text>
          </Col>
        </Row>
        <Form form={formBoot} layout="vertical">
          <Row justify="end" style={{ marginBottom: "25px" }}>
            <Space>
              <Button
                style={{ fontFamily: FONT }}
                onClick={props.onCloseDrawer}
              >
                Cancelar
              </Button>
              <Button
                style={{ fontFamily: FONT }}
                onClick={handleSave}
                type="primary"
              >
                Salvar
              </Button>
            </Space>
          </Row>
          {training.step1 == 4 && (
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="exercise1"
                  label={
                    <span style={{ fontFamily: FONT }}>
                      {getTranslationTypeBoot(training.exercise1)}
                    </span>
                  }
                >
                  <Input
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newExercise1"
                  label={
                    <span style={{ fontFamily: FONT }}>
                      Novo {getTranslationTypeBoot(training.exercise1)}
                    </span>
                  }
                >
                  <Select
                    onChange={(e) => setLoad(e, "1", historicTraining1)}
                  >
                    {historicTraining1.map((item) => {
                      return (
                        <Option value={item.exercise}>
                          {getTranslationTypeBoot(item.exercise)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="load1"
                  label={<span style={{ fontFamily: FONT }}>Carga</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newLoad1"
                  label={<span style={{ fontFamily: FONT }}>Nova Carga</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="week1"
                  label={<span style={{ fontFamily: FONT }}>Semana</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newWeek1"
                  label={<span style={{ fontFamily: FONT }}>Nova Semana</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {training.step2 == 4 && (
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="exercise2"
                  label={
                    <span style={{ fontFamily: FONT }}>
                      {getTranslationTypeBoot(training.exercise2)}
                    </span>
                  }
                >
                  <Input
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newExercise2"
                  label={
                    <span style={{ fontFamily: FONT }}>
                      Novo {getTranslationTypeBoot(training.exercise2)}
                    </span>
                  }
                >
                  <Select
                    onChange={(e) => setLoad(e, "2", historicTraining2)}
                  >
                    {historicTraining2.map((item) => {
                      return (
                        <Option value={item.exercise}>
                          {getTranslationTypeBoot(item.exercise)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="load2"
                  label={<span style={{ fontFamily: FONT }}>Carga</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newLoad2"
                  label={<span style={{ fontFamily: FONT }}>Nova Carga</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="week2"
                  label={<span style={{ fontFamily: FONT }}>Semana</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newWeek2"
                  label={<span style={{ fontFamily: FONT }}>Nova Semana</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {training.step3 == 4 && (
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="exercise3"
                  label={
                    <span style={{ fontFamily: FONT }}>
                      {getTranslationTypeBoot(training.exercise3)}
                    </span>
                  }
                >
                  <Input
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newExercise3"
                  label={
                    <span style={{ fontFamily: FONT }}>
                      Novo {getTranslationTypeBoot(training.exercise3)}
                    </span>
                  }
                >
                  <Select
                    onChange={(e) => setLoad(e, "3", historicTraining3)}
                  >
                    {historicTraining3.map((item) => {
                      return (
                        <Option value={item.exercise}>
                          {getTranslationTypeBoot(item.exercise)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="load3"
                  label={<span style={{ fontFamily: FONT }}>Carga</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newLoad3"
                  label={<span style={{ fontFamily: FONT }}>Nova Carga</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="week3"
                  label={<span style={{ fontFamily: FONT }}>Semana</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newWeek3"
                  label={<span style={{ fontFamily: FONT }}>Nova Semana</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {training.step4 == 4 && (
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="exercise4"
                  label={
                    <span style={{ fontFamily: FONT }}>
                      {getTranslationTypeBoot(training.exercise4)}
                    </span>
                  }
                >
                  <Input
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newExercise4"
                  label={
                    <span style={{ fontFamily: FONT }}>
                      Novo {getTranslationTypeBoot(training.exercise4)}
                    </span>
                  }
                >
                  <Select
                    onChange={(e) => setLoad(e, "4", historicTraining4)}
                  >
                    {historicTraining4.map((item) => {
                      return (
                        <Option value={item.exercise}>
                          {getTranslationTypeBoot(item.exercise)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="load4"
                  label={<span style={{ fontFamily: FONT }}>Carga</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newLoad4"
                  label={<span style={{ fontFamily: FONT }}>Nova Carga</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="week4"
                  label={<span style={{ fontFamily: FONT }}>Semana</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="newWeek4"
                  label={<span style={{ fontFamily: FONT }}>Nova Semana</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    disabled={true}
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Spin>
    </>
  );
};
export default ChangeBoot;
