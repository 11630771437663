import Services from "api/ProjectApiConsumer";

export function getUsersControl(obj) {
  return Services.controlService.getUsersControl(undefined, obj);
}
export function getUsersControlBoot(obj) {
  return Services.controlService.getUsersControlBoot(undefined, obj);
}


export function saveControl(obj) {
  return Services.controlService.saveControl(obj);
}

export function getControlByUser(id) {
  return Services.controlService.getControlByUser(undefined, undefined, id);
}
export function deleteControlById(id) {
  return Services.controlService.deleteControl(undefined, undefined, id);
}
export function getControlByUserBoot(id) {
  return Services.controlService.getControlByUserBoot(undefined, undefined, id);
}