import React, { useEffect, useState } from "react";
import { Spin, Button, Row, Input, Space, Card, Table, Typography } from "antd";
import { ArrowRight, Dumbbell, Search } from "lucide-react";
import { FONT } from "constants/index";
import { notificationErroInternal, notificationSucess } from "util/api-utils";
import { ColumnsUser } from "./columns-table";
import DrawerApp from "components/drawer-app";

import { formNewUserControl } from "./load-control-drawer-new-control";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getUsersControl, saveControl } from "services/control/control-service";
import FormLoadUserComparation from "./load-control-drawer-comparation";
import FormScorePoint from "./load-control-drawer-score-point";

dayjs.extend(customParseFormat);
const dateFormat = "DD/MM/YYYY";

const { Text } = Typography;

const LoadControlClientUnique = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openComparation, setOpenComparation] = useState(false);
  const formRef = React.useRef(null);
  const [editId, setEditId] = useState(null);
  const [imgAvatar, setImgAvatar] = useState("");

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    setLoading(true);
    getData();
  }

  function getData() {
    let obj = {
      search: search,
    };

    if (search == null || search == "") {
      obj = {};
    }
    getUsersControl(obj)
      .then((res) => {
        res = res.map((obj) => ({
          ...obj,
          comparation: comparation,
          edit: edit,
        }));
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  const showDrawer = () => {
    setOpen(true);
  };

  const showDrawerComparation = () => {
    setOpenComparation(true);
  };

  const onClose = () => {
    formRef.current?.resetFields();
    setEditId(null);
    setOpen(false);
    refreshData();
  };

  const onCloseComparation = () => {
    setEditId(null);
    setOpenComparation(false);
    refreshData();
  };

  function edit(row) {
    setEditId(row.id);
    setImgAvatar(row.img);
    setTimeout(() => {
      formRef.current?.setFieldsValue({
        name: row.name,
        date: dayjs(new Date()),
        cmjFirst: row.nowControl?.cmjFirst,
        cmjSecond: row.nowControl?.cmjSecond,
        fatigue: row.nowControl?.fatigue,
        humor: row.nowControl?.humor,
        pain: row.nowControl?.pain,
        sleep: row.nowControl?.sleep,
        stress: row.nowControl?.stress,
      });
    }, 300);

    showDrawer();
  }
  function comparation(row) {
    setEditId(row.id);
    showDrawerComparation();
  }

  const handleNewControl = () => {
    setLoading(true);

    formRef?.current
      ?.validateFields()
      .then((values) => {
        const userSave = data.find((item) => item.id == editId);
        values.userId = editId;
        values.reviewId = userSave.lastReview.id;
        values.id = userSave.nowControl?.id;
        values.date = values.date.format(dateFormat);
        saveControl(values)
          .then(() => {
            setLoading(false);
            notificationSucess();
            onClose();
          })
          .catch((err) => {
            setLoading(false);
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      })
      .catch((errors) => {
        setLoading(false);
        console.log(errors);
      });
  };

  return (
    <>
      {
        <div className="container-load-client">
          <Spin size="large" spinning={loading}>
            <Row gutter={24} style={{ padding: "10px" }}>
              <Space>
                <Input
                  placeholder="Pesquisar Aluno"
                  onChange={(value) => setSearch(value.target.value)}
                  suffix={<Search size={18} color="#e0e0e0" />}
                  style={{ height: "40px" }}
                  onPressEnter={refreshData}
                />

                <Button
                  style={{ fontFamily: FONT }}
                  type="primary"
                  icon={
                    <ArrowRight
                      size={18}
                      style={{ marginBottom: "-3px", marginRight: "7px" }}
                    />
                  }
                  onClick={refreshData}
                >
                  Filtrar
                </Button>
              </Space>
            </Row>
            <Row gutter={[24, 24]} style={{ marginLeft: "0px" }}>
              <Space
                direction="vertical"
                size={"small"}
                style={{
                  flex: "1 1 500px",
                  minWidth: outerWidth < 501 ? "0px" : "500px",
                }}
              >
                <Card
                  id="card-table"
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1 }}>
                        <Dumbbell
                          size={18}
                          style={{ marginBottom: "-4px", marginRight: "10px" }}
                        />
                        <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                          Controle dos Alunos
                        </Text>
                      </div>
                    </div>
                  }
                  bordered={false}
                >
                  <Table
                    columns={ColumnsUser()}
                    dataSource={data}
                    pagination={{ pageSize: 10, showSizeChanger: false }}
                    locale={{
                      emptyText: "Sem registro",
                    }}
                  />
                </Card>
              </Space>
            </Row>
          </Spin>
   
          <DrawerApp
            open={open}
            onClose={onClose}
            title={"Registrar Controle"}
            body={formNewUserControl(formRef, imgAvatar)}
            size={"large"}
            actionSave={handleNewControl}
            width={outerWidth < 501 ? "100%" : "60%"}
        
          />
          <DrawerApp
            open={openComparation}
            onClose={onCloseComparation}
            title={"Histórico"}
            body={<FormLoadUserComparation userId={editId} />}
            size={"large"}
            width={outerWidth < 501 ? "100%" : "90%"}
            isOnlyView={true}

            extra={
              <Button
                style={{
                  fontFamily: FONT,
                  background: "#14B8A6",
                  color: "#ffffff",
                }}
                onClick={() => setOpenResultPoint(true)}
              >
                Score Bem Estar
              </Button>
            }
          />
       
        </div>
      }
    </>
  );
};
export default LoadControlClientUnique;
