import RestAPI from "./RestApiConsumer";
import { API_BASE_URL } from "constants/index";

export const api = new RestAPI(API_BASE_URL);

//Login Controller
api.addEndpoint("login", undefined, "POST", false);
api.addRichParametersEndpoint(
  "users/generate-code",
  "generateCode",
  "POST",
  false
);
api.addEndpoint("users/new-password", "newPassword", "POST", false);

//Users

api.addEndpointWithParams("users", "getUsers", "GET", true);
api.addEndpoint("users", "saveUser", "POST", true);
api.addEndpoint("users", "putUser", "PUT", true);
api.addRichParametersEndpoint("users", "deleteUser", "DELETE", true);
api.addRichParametersEndpoint("users", "getById", "GET", true);

//Reviews
api.addEndpointWithParams("reviews", "getUsersReviews", "GET", true);
api.addEndpointWithParams(
  "reviews/ranking",
  "getUsersReviewsRanking",
  "GET",
  true
);
api.addEndpointWithParams(
  "reviews/ranking-sprint",
  "getUsersReviewsRankingSprint",
  "GET",
  true
);
api.addEndpoint("reviews", "createReview", "POST", true);
api.addEndpoint("reviews", "putReview", "PUT", true);
api.addRichParametersEndpoint("reviews", "deleteReview", "DELETE", true);
api.addRichParametersEndpoint("reviews", "getReviewUserById", "GET", true);
api.addEndpoint("reviews/sprint", "createSprint", "POST", true);
api.addRichParametersEndpoint(
  "reviews/sprint",
  "getSprintUserById",
  "GET",
  true
);
api.addRichParametersEndpoint(
  "reviews/sprint/open",
  "getReviewUserOpenById",
  "GET",
  true
);

api.addEndpoint("reviews/sprint", "putSprint", "PUT", true);
api.addRichParametersEndpoint("reviews/sprint", "deleteSprint", "DELETE", true);

//Planning
api.addEndpointWithParams("planning", "getUsersPlanning", "GET", true);
api.addEndpointWithParams("planning", "savePlanning", "POST", true);
api.addRichParametersEndpoint("planning", "getPlanningUserById", "GET", true);
api.addRichParametersEndpoint("planning", "deletePlanning", "DELETE", true);
api.addEndpoint("planning/training", "getUsersPlanningToTraining", "GET", true);
api.addEndpoint("planning/training", "saveTraining", "POST", true);
api.addEndpoint("planning/update-training", "updateTraining", "POST", true);
api.addEndpoint(
  "planning/training-finish",
  "getUsersPlanningFinishTraining",
  "GET",
  true
);
api.addEndpoint("planning/annotation", "saveAnnotation", "POST", true);

//Boot
api.addEndpointWithParams("boot", "getUsersBoot", "GET", true);
api.addEndpoint("boot/training", "getUsersBootToTraining", "GET", true);
api.addEndpoint("boot/annotation", "saveAnnotationBoot", "POST", true);
api.addEndpoint("boot/training", "saveTrainingBoot", "POST", true);
api.addEndpoint("boot/training-open", "openTraining", "POST", true);
api.addRichParametersEndpoint(
  "boot/training-close",
  "closeTraining",
  "POST",
  true
);
api.addEndpoint("boot/training-change", "changeTrainingBoot", "POST", true);

//Control
api.addEndpointWithParams("control", "getUsersControl", "GET", true);
api.addEndpointWithParams("control/boot", "getUsersControlBoot", "GET", true);
api.addEndpoint("control", "saveControl", "POST", true);
api.addRichParametersEndpoint("control/user", "getControlByUser", "GET", true);
api.addRichParametersEndpoint("control", "deleteControl", "DELETE", true);
api.addRichParametersEndpoint("control/user-boot", "getControlByUserBoot", "GET", true);


//Users

export const userService = {
  login: api.endpoints.login,
  generateCode: api.endpoints.generateCode,
  newPassword: api.endpoints.newPassword,
};

//user

export const userAdminService = {
  getUsers: api.endpoints.getUsers,
  saveUser: api.endpoints.saveUser,
  putUser: api.endpoints.putUser,
  deleteUser: api.endpoints.deleteUser,
  getById: api.endpoints.getById,
};

//review user

export const reviewService = {
  getUsersReviews: api.endpoints.getUsersReviews,
  createReview: api.endpoints.createReview,
  putReview: api.endpoints.putReview,
  deleteReview: api.endpoints.deleteReview,
  getReviewUserById: api.endpoints.getReviewUserById,
  createSprint: api.endpoints.createSprint,
  getSprintUserById: api.endpoints.getSprintUserById,
  putSprint: api.endpoints.putSprint,
  deleteSprint: api.endpoints.deleteSprint,
  getUsersReviewsRanking: api.endpoints.getUsersReviewsRanking,
  getReviewUserOpenById: api.endpoints.getReviewUserOpenById,
  getUsersReviewsRankingSprint: api.endpoints.getUsersReviewsRankingSprint,
};

//planning user

export const planningService = {
  getUsersPlanning: api.endpoints.getUsersPlanning,
  savePlanning: api.endpoints.savePlanning,
  getPlanningUserById: api.endpoints.getPlanningUserById,
  deletePlanning: api.endpoints.deletePlanning,
  getUsersPlanningToTraining: api.endpoints.getUsersPlanningToTraining,
  saveTraining: api.endpoints.saveTraining,
  updateTraining: api.endpoints.updateTraining,
  getUsersPlanningFinishTraining: api.endpoints.getUsersPlanningFinishTraining,
  saveAnnotation: api.endpoints.saveAnnotation,
};

//boot user

export const bootService = {
  getUsersBoot: api.endpoints.getUsersBoot,
  getUsersBootToTraining: api.endpoints.getUsersBootToTraining,
  saveAnnotationBoot: api.endpoints.saveAnnotationBoot,
  saveTrainingBoot: api.endpoints.saveTrainingBoot,
  openTraining: api.endpoints.openTraining,
  closeTraining: api.endpoints.closeTraining,
  changeTrainingBoot: api.endpoints.changeTrainingBoot,
};

//control user

export const controlService = {
  getUsersControl: api.endpoints.getUsersControl,
  getUsersControlBoot: api.endpoints.getUsersControlBoot,
  saveControl: api.endpoints.saveControl,
  getControlByUser: api.endpoints.getControlByUser,
  deleteControl: api.endpoints.deleteControl,
  getControlByUserBoot:  api.endpoints.getControlByUserBoot,
};

const Services = {
  userService,
  reviewService,
  planningService,
  userAdminService,
  bootService,
  controlService,
};

export default Services;
