import { Col, Divider, Row } from "antd";
import React from "react";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">
      {title} - {content}
    </p>
  </div>
);

const FormScorePoint = () => {
  return (
    <div>
      <div style={{ marginTop: "20px" }}>
        <div>
          <p className="site-description-item-profile-p">Fadiga</p>

          <Row>
            <Col span={24}>
              <DescriptionItem
                title="5"
                content="Sem fadiga; sente-se energizado e pronto para treinar"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="4"
                content="Leve fadiga; sente-se bem para treinar"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="3"
                content="Fadiga moderada; sente-se disposto, mas sem força total"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="2"
                content="Fadiga elevada; sente-se cansado e com pouca energia"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="1"
                content="Fadiga extrema; sente-se esgotado, sem energia para treinar"
              />
            </Col>
          </Row>
          <Divider />

          <p className="site-description-item-profile-p">Dor Muscular</p>

          <Row>
            <Col span={24}>
              <DescriptionItem title="5" content="Sem dor muscular" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="4"
                content="Leve dor muscular, sem limitar o treino"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="3"
                content="Dor muscular moderada, sem limitar o treino"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="2"
                content="Dor muscular intensa, dificuldade para executar o treino"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="1"
                content="Dor muscular severa, incapaz de treinar adequadamente"
              />
            </Col>
          </Row>
          <Divider />
          <p className="site-description-item-profile-p">Humor</p>

          <Row>
            <Col span={24}>
              <DescriptionItem
                title="5"
                content="Se sente muito bem, motivado e positivo"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="4"
                content="Se sente bem, com energia para o treino"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="3"
                content="Se sente neutro, sem motivação extra"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="2"
                content="Se sente irritado ou desmotivado"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="1"
                content="Se sente muito mal, desmotivado ou irritado"
              />
            </Col>
          </Row>
          <Divider />
          <p className="site-description-item-profile-p">Estresse</p>

          <Row>
            <Col span={24}>
              <DescriptionItem
                title="5"
                content="Sem estresse, tranquilo e focado"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="4"
                content="Leve estresse, sem impacto na performance"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="3"
                content="Estresse moderado, começa a afetar o foco"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="2"
                content="Alto nível de estresse, comprometendo o foco e desempenho"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="1"
                content="Estresse extremo, bloqueio mental e dificuldade de concentração"
              />
            </Col>
          </Row>
          <Divider />
          <p className="site-description-item-profile-p">Sono</p>

          <Row>
            <Col span={24}>
              <DescriptionItem
                title="5"
                content="Dormiu muito bem (8h ou mais), sem interrupções, acordou descansado e revigorado"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="4"
                content="Dormiu bem (7h a 8h), com poucas interrupções, acordou descansado"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="3"
                content="Dormiu moderadamente (6h a 7h), com algumas interrupções"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="2"
                content="Dormiu mal (4h a 6h), sono interrompido e acordou cansado"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="1"
                content="Dormiu muito mal (menos de 4h), com várias interrupções e acordou exausto"
              />
            </Col>
          </Row>
          <Divider />
        </div>
      </div>
    </div>
  );
};

export default FormScorePoint;
