import React, { useEffect, useState } from "react";
import {
  Spin,
  Button,
  Row,
  Input,
  Space,
  Card,
  Table,
  Typography,
  Modal,
  DatePicker,
} from "antd";
import {
  ArrowRight,
  CircleAlert,
  ExternalLink,
  Medal,
  Search,
} from "lucide-react";
import { FONT, USER_STORAGE } from "constants/index";
import { notificationErroInternal, notificationSucess } from "util/api-utils";
import { ColumnsUser } from "./columns-table";
import DrawerApp from "components/drawer-app";
import {
  getUsersReviews,
  getReviewUserById,
  createSprint,
  getSprintUserById,
  putSprint,
  deleteSprint,
} from "services/review/review-service";
import FormNewUserReview from "./reviews-drawer-new-review";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import FormUserResult from "./reviews-drawer-result";
import FormUserComparation from "./reviews-drawer-comparation";
import FormNewRecord from "./reviews-drawer-new-record";
import moment from "moment";
import FormUserResultPoint from "./reviews-drawer-result-point";
import FormNewUserReviewMultiple from "./reviews-drawer-new-review-multiple";
import FormUserComparationBoot from "./reviews-drawer-comparation-boot";
import { getControlByUserBoot } from "services/control/control-service";

dayjs.extend(customParseFormat);
const dateFormat = "DD/MM/YYYY";
const { RangePicker } = DatePicker;

const { Text } = Typography;
const { confirm } = Modal;

const ReviewsClient = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openComparation, setOpenComparation] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const [editId, setEditId] = useState(null);
  const [imgAvatar, setImgAvatar] = useState("");
  const formRefRecord = React.useRef(null);
  const [openRecord, setOpenRecord] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({});
  const [dataSprint, setDataSprint] = useState([]);
  const [editSprintId, setEditSprintId] = useState(null);
  const [openResultPoint, setOpenResultPoint] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openDrawers, setOpenDrawers] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rengeSearch, setRengeSearch] = useState(null);
  const [isBoot, setIsBoot] = useState(false);

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    setLoading(true);
    getData();
  }

  function getData() {
    let obj = {
      search: search,
    };

    if (search == null || search == "") {
      obj = {};
    }
    if (rengeSearch && rengeSearch.length == 2) {
      obj.start = rengeSearch[0]?.format(dateFormat);
      obj.end = rengeSearch[1]?.format(dateFormat);
    }

    getUsersReviews(obj)
      .then((res) => {
        res = res.map((obj) => ({
          ...obj,
          record: record,
          result: result,
          comparation: comparation,
          edit: edit,
          profile:
            obj.role == "ADMIN"
              ? "Administrador"
              : obj.role == "USER_ADMIN"
              ? "Professor"
              : "Aluno",
        }));

        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  const showDrawer = () => {
    setOpen(true);
  };

  const showDrawerComparation = () => {
    setOpenComparation(true);
  };
  const showDrawerResult = () => {
    setOpenResult(true);
  };

  const showDrawerRecord = () => {
    setOpenRecord(true);
  };
  const onClose = () => {
    setEditId(null);
    setOpen(false);
    refreshData();
  };
  const onCloseDrawers = () => {
    setOpenDrawers(false);
    refreshData();
    setSelectedRowKeys([]);
    setIsEdit(false);
  };
  const onCloseRecord = () => {
    formRefRecord.current?.resetFields();
    setEditId(null);
    setEditSprintId(null);
    setOpenRecord(false);
  };
  const onCloseResult = () => {
    setDataDrawer({ reviews: [] });
    setEditId(null);
    setOpenResult(false);
  };
  const onCloseComparation = () => {
    setEditId(null);
    setDataDrawer({ reviews: [] });
    setOpenComparation(false);
    setIsBoot(false);
    refreshData();
  };

  const onCloseResultPoint = () => {
    setOpenResultPoint(false);
  };
  function edit(row) {
    setEditId(row.id);
    setImgAvatar(row.img);
    showDrawer();
  }
  function comparation(row) {
    setEditId(row.id);
    setImgAvatar(row.img);
    setLoading(true);
    setIsBoot(row.isBoot);
    var funcGet = row.isBoot == true ? getControlByUserBoot : getReviewUserById;
    funcGet(row.id)
      .then((res) => {
        setDataDrawer(res);
        setLoading(false);
        showDrawerComparation();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  function editSprint(row) {
    setLoading(true);
    setEditSprintId(row.id);
    setTimeout(() => {
      formRefRecord.current?.setFieldsValue({
        date: row.sprintDate ? dayjs(row.sprintDate) : null,
        typeRecord: row.typeRecord,
        record: row.record,
      });
      setLoading(false);
    }, 300);
  }
  function deleteSprintItem(row) {
    confirm({
      title: (
        <div>
          <CircleAlert size={22} style={{ marginBottom: "-5px" }} />
          <span style={{ marginLeft: "10px", fontFamily: FONT }}>
            Tem certeza que deseja deletar o sprint do dia '
            {moment(row.sprintDate).format("DD/MM/YYYY")}' ?
          </span>
        </div>
      ),
      icon: null,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        setLoading(true);
        deleteSprint(row.id)
          .then(() => {
            getDataSprintUserById(editId, row.name);
            notificationSucess();
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      },
      onCancel() {},
    });
  }
  function record(row) {
    setLoading(true);
    setEditId(row.id);
    setImgAvatar(row.img);
    getDataSprintUserById(row.id, row.name);
  }
  function getDataSprintUserById(id, name) {
    getSprintUserById(id)
      .then((res) => {
        setTimeout(() => {
          res = res.map((obj) => ({
            ...obj,
            edit: editSprint,
            delete: deleteSprintItem,
          }));
          setDataSprint(res);
          formRefRecord.current?.setFieldsValue({
            name: name,
          });
          setLoading(false);
        }, 300);
        showDrawerRecord();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  function result(row) {
    setEditId(row.id);
    setImgAvatar(row.img);

    setLoading(true);
    getReviewUserById(row.id)
      .then((res) => {
        setDataDrawer(res);
        setLoading(false);
        showDrawerResult();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  const handleNewSprint = () => {
    setLoading(true);
    formRefRecord?.current
      ?.validateFields()
      .then((values) => {
        values.userId = editId;
        values.date = values.date.format(dateFormat);

        var funcSave = createSprint;
        if (editSprintId != null) {
          funcSave = putSprint;
          values.id = editSprintId;
        }
        funcSave(values)
          .then(() => {
            formRefRecord.current?.setFieldsValue({
              date: "",
              typeRecord: "",
              record: "",
            });
            getDataSprintUserById(
              editId,
              formRefRecord.current?.getFieldValue("name")
            );
            setEditSprintId(null);
            notificationSucess();
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      })
      .catch((errors) => {
        setLoading(false);
        console.log(errors);
      });
  };
  const onSelectChange = (newSelectedRowKeys) => {
    const newSelectedKeys = [...selectedRowKeys];

    newSelectedRowKeys.forEach((key) => {
      if (!newSelectedKeys.includes(key)) {
        newSelectedKeys.push(key);
      }
    });

    const allKeys = newSelectedKeys.filter((key) =>
      newSelectedRowKeys.includes(key)
    );
    if (allKeys.length <= 6) {
      setSelectedRowKeys(allKeys);
    }
  };
  
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "10%",
    preserveSelectedRowKeys: true,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const mountFilter = () => {
    if (outerWidth > 501) {
      return (
        <Row gutter={24} style={{ padding: "10px" }}>
          <Space>
            <Input
              placeholder="Pesquisar Aluno"
              onChange={(value) => setSearch(value.target.value)}
              suffix={<Search size={18} color="#e0e0e0" />}
              style={{ height: "40px" }}
              onPressEnter={refreshData}
            />
            <RangePicker
              style={{ height: 40 }}
              placeholder={["Data Inicial", "Data Final"]}
              format={dateFormat}
              onChange={(value) => {
                setRengeSearch(value);
              }}
            />

            <Button
              style={{ fontFamily: FONT }}
              type="primary"
              icon={
                <ArrowRight
                  size={18}
                  style={{ marginBottom: "-3px", marginRight: "7px" }}
                />
              }
              onClick={refreshData}
            >
              Filtrar
            </Button>
            {hasSelected && (
              <>
                <Space>
                  <Button
                    style={{ fontFamily: FONT, marginLeft: "-15px" }}
                    type="primary"
                    icon={
                      <ExternalLink
                        size={18}
                        style={{ marginBottom: "-3px", marginRight: "7px" }}
                      />
                    }
                    onClick={() => setOpenDrawers(true)}
                  >
                    Abrir Todos
                  </Button>
                  <Button
                    style={{
                      fontFamily: FONT,
                      marginLeft: "-15px",
                      backgroundColor: "#09ac9c",
                    }}
                    type="primary"
                    icon={
                      <ExternalLink
                        size={18}
                        style={{ marginBottom: "-3px", marginRight: "7px" }}
                      />
                    }
                    onClick={() => {
                      setIsEdit(true);
                      setOpenDrawers(true);
                    }}
                  >
                    Editar Todos
                  </Button>
                </Space>
                {hasSelected
                  ? `${selectedRowKeys.length} alunos selecionados`
                  : null}
              </>
            )}
          </Space>
        </Row>
      );
    } else {
      return (
        <>
          <Row gutter={24} style={{ padding: "10px" }}>
            <Space>
              <Input
                placeholder="Pesquisar Aluno"
                onChange={(value) => setSearch(value.target.value)}
                suffix={<Search size={18} color="#e0e0e0" />}
                style={{ height: "40px" }}
                onPressEnter={refreshData}
              />
              <RangePicker
                placeholder="Pesquisar Avaliação"
                onChange={(value) => {
                  setRengeSearch(value);
                }}
                onPressEnter={refreshData}
              />

              <Button
                style={{ fontFamily: FONT }}
                type="primary"
                icon={
                  <ArrowRight
                    size={18}
                    style={{ marginBottom: "-3px", marginRight: "7px" }}
                  />
                }
                onClick={refreshData}
              >
                Filtrar
              </Button>
            </Space>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Space>
              {hasSelected && (
                <>
                  {hasSelected
                    ? `${selectedRowKeys.length} alunos selecionados`
                    : null}
                  <Button
                    style={{ fontFamily: FONT, marginLeft: "50%" }}
                    type="primary"
                    icon={
                      <ExternalLink
                        size={18}
                        style={{ marginBottom: "-3px", marginRight: "7px" }}
                      />
                    }
                    onClick={() => setOpenDrawers(true)}
                  >
                    Abrir Todos
                  </Button>
                </>
              )}
            </Space>
          </Row>
        </>
      );
    }
  };
  return (
    <div className="container-reviews-client">
      <Spin size="large" spinning={loading}>
        {mountFilter()}
        <Row gutter={[24, 24]} style={{ marginLeft: "0px" }}>
          <Space
            direction="vertical"
            size={"small"}
            style={{
              flex: "1 1 500px",
              minWidth: outerWidth < 501 ? "0px" : "500px",
            }}
          >
            <Card
              id="card-table"
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <Medal
                      size={18}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                      Avaliações dos Alunos
                    </Text>
                  </div>
                </div>
              }
              bordered={false}
            >
              <Table
                rowKey={"id"}
                columns={ColumnsUser()}
                dataSource={data}
                rowSelection={rowSelection}
                pagination={{ pageSize: 10, showSizeChanger: false }}
                locale={{
                  emptyText: "Sem registro",
                }}
                scroll={{ x: 500 }}
              />
            </Card>
          </Space>
        </Row>
      </Spin>

      <DrawerApp
        open={open}
        onClose={onClose}
        title={"Nova Avaliação"}
        body={
          <FormNewUserReview
            user={data.find((item) => item.id == editId)}
            showSprints={true}
            onClose={onClose}
            lastReview={data.find((item) => item.id == editId)?.lastReview}
          />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openComparation}
        onClose={onCloseComparation}
        title={"Avaliações"}
        body={
          isBoot ? (
            <FormUserComparationBoot
              dataDrawer={dataDrawer}
              imgAvatar={imgAvatar}
            />
          ) : (
            <FormUserComparation
              dataDrawer={dataDrawer}
              imgAvatar={imgAvatar}
            />
          )
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "90%"}
      />
      <DrawerApp
        open={openResult}
        onClose={onCloseResult}
        title={"Resultados"}
        body={<FormUserResult dataDrawer={dataDrawer} imgAvatar={imgAvatar} />}
        size={"large"}
        width={outerWidth < 501 ? "100%" : "90%"}
        loading={loading}
        isOnlyView={true}
        extra={
          <Button
            style={{
              fontFamily: FONT,
              background: "#14B8A6",
              color: "#ffffff",
            }}
            onClick={() => setOpenResultPoint(true)}
          >
            Sistema de Pontos
          </Button>
        }
      />
      <DrawerApp
        open={openResultPoint}
        onClose={onCloseResultPoint}
        title={"Sistema de Pontos"}
        body={<FormUserResultPoint />}
        size={"smail"}
        isOnlyView={true}
      />

      <DrawerApp
        open={openRecord}
        onClose={onCloseRecord}
        title={"Sprints"}
        body={
          <FormNewRecord
            dataDrawer={dataSprint}
            imgAvatar={imgAvatar}
            loading={loading}
            formRef={formRefRecord}
          />
        }
        actionSave={handleNewSprint}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      {openDrawers && (
        <DrawerApp
          open={openDrawers}
          onClose={onCloseDrawers}
          title={isEdit ? "Editar Avaliação" : "Nova Avaliação"}
          body={
            <FormNewUserReviewMultiple
              data={data.filter((item) => selectedRowKeys.includes(item.id))}
              onClose={onCloseDrawers}
              isEdit={isEdit}
            />
          }
          isOnlyView={true}
          width={outerWidth < 501 ? "100%" : "65%"}
          size={"large"}
        />
      )}
    </div>
  );
};
export default ReviewsClient;
