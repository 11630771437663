import React, { useEffect, useState } from "react";

import {
  Row,
  Input,
  Typography,
  Form,
  Col,
  DatePicker,
  InputNumber,
  Divider,
  Avatar,
  Space,
  Card,
  Table,
  Spin,
  Modal,
  Button,
} from "antd";
import {
  ArrowUpFromLine,
  BicepsFlexed,
  BookUp2,
  CircleAlert,
  Gauge,
  Medal,
  TrendingUpDown,
  UserRound,
} from "lucide-react";
import { FONT } from "constants/index";
import {
  ColumnsUserCmj,
  ColumnsUserComparation,
  ColumnsUserWellBeing,
} from "./columns-table";
import {
  chartBar,
  chartBarOne,
  chartLiner,
  chartLinerDot,
  chartThreeBar,
} from "./load-control-drawer-charts";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DrawerApp from "components/drawer-app";

import { notificationErroInternal, notificationSucess } from "util/api-utils";
import moment from "moment";
import {
  deleteControlById,
  getControlByUser,
  getControlByUserBoot,
  saveControl,
} from "services/control/control-service";
import { formNewUserControl } from "./load-control-drawer-new-control";
import FormScorePoint from "./load-control-drawer-score-point";
dayjs.extend(customParseFormat);
const { Text } = Typography;
const dateFormat = "DD/MM/YYYY";
const { confirm } = Modal;
const FormLoadUserComparation = (props) => {
  const [formComp] = Form.useForm();
  const [imgAvatar, setImgAvatar] = useState(null);
  const [userControl, setUserControl] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setDataDrawer] = useState([]);
  const formRef = React.useRef(null);
  const [openResultPoint, setOpenResultPoint] = useState(false);
  const [openControl, setOpenControl] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    if (props.userId) {
      setLoading(true);
      getData();
    }
  }, [props]);

  function getData() {
    var funcGet =
      props.isBoot == true ? getControlByUserBoot : getControlByUser;
    funcGet(props.userId)
      .then((res) => {
        setUserControl(res);
        const dataResult = res.controlGrouped.map((obj) => ({
          ...obj,
          changeActualComp: changeActual,
          showDrawer: showDrawer,
        }));
        setDataDrawer(dataResult);
        setImgAvatar(res.img);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  useEffect(() => {
    formComp.setFieldsValue({
      name: userControl.controlActual?.name,
      reviewActual: userControl.controlActual?.reviewDate
        ? dayjs(userControl.controlActual?.reviewDate)
        : null,
      cmj: userControl.controlActual?.cmj,
    });
  }, [userControl]);

  const changeActual = function (row) {
    setLoading(true);
    setTimeout(() => {
      let newUserSelected = userControl;
      newUserSelected.controlActual = row;
      setUserControl(newUserSelected);
      setLoading(false);
    }, 300);
  };

  const showDrawer = (row) => {
    let newUserSelected = userControl;
    newUserSelected.controlActual = row;
    setUserControl(newUserSelected);
  };

  function chartLoad() {
    const controls = userControl.controlActual?.controls ?? [];
    const chartControl = [];

    chartControl.push({
      load: null,
      vmpActual: null,
      vmpComparation: null,
    });
    chartControl.push({
      load: null,
      vmpActual: null,
      vmpComparation: userControl.controlActual?.cmj,
    });

    controls.forEach((item, index) => {
      const typeTraining = index % 2 !== 0 ? "B" : "A";
      chartControl.push({
        load: "Semana - " + typeTraining,
        vmpActual: item.cmjFirst,
        vmpComparation: userControl.controlActual?.cmj,
      });
    });
    chartControl.push({
      load: null,
      vmpActual: null,
      vmpComparation: userControl.controlActual?.cmj,
    });
    chartControl.push({
      load: null,
      vmpActual: null,
      vmpComparation: null,
    });
    return chartControl;
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const diferencaPre = payload[1]?.value - payload[0]?.value;
      const diferencaPos = payload[2]?.value - payload[0]?.value;

      const percentualPre = (diferencaPre / payload[0]?.value) * 100;
      const percentualPos = (diferencaPos / payload[0]?.value) * 100;

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "6px",
            fontFamily: FONT,
          }}
        >
          <p className="intro">{`${label}`}</p>
          <p className="intro">
            Salto Base:{" "}
            <span style={{ color: "#14B8A6" }}>{payload[0]?.value}</span>
          </p>
          {payload[1]?.value && (
            <p className="intro">
              Salto PRÉ:{" "}
              <span style={{ color: "#3B82F6" }}>{payload[1]?.value}</span>
            </p>
          )}
          {payload[1]?.value && (
            <p className="intro">
              % Queda PRÉ:{" "}
              <span
                style={{
                  color:
                    percentualPre < -10
                      ? "#EC6D62"
                      : percentualPre <= -6 && percentualPre >= -10
                      ? "#acb52d"
                      : "#14B8A6",
                }}
              >
                {percentualPre.toFixed(2)}%
              </span>
            </p>
          )}

          {payload[2]?.value && (
            <p className="intro">
              Salto PÓS:{" "}
              <span style={{ color: "#3B82F6" }}>{payload[2]?.value}</span>
            </p>
          )}
          {payload[2]?.value && (
            <p className="intro">
              % Queda PÓS:{" "}
              <span
                style={{
                  color:
                    percentualPos < -10
                      ? "#EC6D62"
                      : percentualPos <= -6 && percentualPos >= -10
                      ? "#acb52d"
                      : "#14B8A6",
                }}
              >
                {percentualPos.toFixed(2)}%
              </span>
            </p>
          )}
        </div>
      );
    }

    return null;
  };

  function mountChartBarLoad() {
    const controls = userControl.controlActual?.controls ?? [];

    return controls.map((item, index) => {
      const typeTraining = index % 2 !== 0 ? "B" : "A";

      return {
        load: "Semana - " + typeTraining,
        vmpEsperada: userControl.controlActual?.cmj,
        vmpAtual: item.cmjFirst,
        vmpAtual2: item.cmjSecond,
      };
    });
  }
  function mountChartBarOne() {
    const controls = userControl.controlActual?.controls ?? [];

    return controls.map((item, index) => {
      const typeTraining = index % 2 !== 0 ? "B" : "A";

      return {
        week: "Semana - " + typeTraining,
        averageWellBeing: item.averageWellBeing,
      };
    });
  }
  function deleteControl(row) {
    confirm({
      title: (
        <div>
          <CircleAlert size={22} style={{ marginBottom: "-5px" }} />
          <span style={{ marginLeft: "10px", fontFamily: FONT }}>
            Tem certeza que deseja deletar o controle do dia '
            {moment(row.date).format("DD/MM/YYYY")}' ?
          </span>
        </div>
      ),
      icon: null,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        setLoading(true);

        deleteControlById(row.id)
          .then(() => {
            getData();
            notificationSucess();
          })
          .catch((err) => {
            setLoading(false);
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      },
      onCancel() {},
    });
  }
  const onClose = () => {
    formRef.current?.resetFields();
    setEditId(null);
    setOpenControl(false);
  };
  const onCloseResultPoint = () => {
    setOpenResultPoint(false);
  };

  function editControl(row) {
    setEditId(row.id);
    setTimeout(() => {
      formRef.current?.setFieldsValue({
        name: userControl.controlActual?.name,
        date: dayjs(new Date()),
        cmjFirst: row.cmjFirst,
        cmjSecond: row.cmjSecond,
        fatigue: row.fatigue,
        humor: row.humor,
        pain: row.pain,
        sleep: row.sleep,
        stress: row.stress,
      });
    }, 300);

    showDrawerControl();
  }
  const showDrawerControl = () => {
    setOpenControl(true);
  };

  const handleNewControl = () => {
    setLoading(true);

    formRef?.current
      ?.validateFields()
      .then((values) => {
        values.userId = props.userId;
        values.reviewId = userControl.controlActual?.reviewId;
        values.id = editId;
        values.date = values.date.format(dateFormat);
        saveControl(values)
          .then(() => {
            getData();
            notificationSucess();
            onClose();
          })
          .catch((err) => {
            setLoading(false);
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      })
      .catch((errors) => {
        setLoading(false);
        console.log(errors);
      });
  };

  return (
    <>
      <Spin size="large" spinning={loading}>
        <Form form={formComp} layout="vertical">
          <Row
            gutter={16}
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            <Col span={24}>
              <Avatar
                src={imgAvatar}
                icon={<UserRound size={120} />}
                size={120}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Space direction="vertical" size={"small"}>
              <Card
                id="card-table"
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Medal
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                        Histórico de Controle
                      </Text>
                    </div>
                  </div>
                }
                bordered={false}
              >
                <Table
                  columns={ColumnsUserComparation(data)}
                  dataSource={data}
                  pagination={{ pageSize: 2, showSizeChanger: false }}
                  locale={{
                    emptyText: "Sem registro",
                  }}
                />
              </Card>
            </Space>
          </Row>
          <Divider />

          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Form.Item
                name="name"
                label={<span style={{ fontFamily: FONT }}>Aluno</span>}
              >
                <Input
                  disabled={true}
                  style={{ fontFamily: FONT }}
                  defaultValue={userControl.name}
                />
              </Form.Item>
            </Col>
            <Col span={outerWidth < 501 ? 24 : 6} style={{ marginTop: "10px" }}>
              <Form.Item
                name="reviewActual"
                label={<span style={{ fontFamily: FONT }}>Avaliação Base</span>}
              >
                <DatePicker
                  style={{ fontFamily: FONT, width: "100%" }}
                  placeholder={["", ""]}
                  format={dateFormat}
                  suffixIcon={null}
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col span={outerWidth < 501 ? 24 : 6} style={{ marginTop: "10px" }}>
              <Form.Item
                name="cmj"
                label={<span style={{ fontFamily: FONT }}>Salto Base</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  disabled={true}
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                id="card-table"
                style={{ minHeight: "500px" }}
                title={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <TrendingUpDown
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                        Saltos
                      </Text>
                    </div>
                  </div>
                }
                bordered={false}
              >
                <Table
                  columns={ColumnsUserCmj(deleteControl, editControl)}
                  dataSource={userControl.controlActual?.controls ?? []}
                  pagination={false}
                  size="small"
                  locale={{
                    emptyText: "Sem registro",
                  }}
                  scroll={{
                    y: 413,
                  }}
                />
              </Card>
            </Col>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Gauge
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Tendência de Salto
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartLinerDot(
                  chartLoad(),
                  "load",
                  "vmpActual",
                  "vmpComparation",
                  "Salto",
                  "Salto Base",
                  5
                )}
              </Card>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={24} style={{ marginTop: "10px" }}>
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowUpFromLine
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Controle de Carga
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartThreeBar(
                  mountChartBarLoad(),
                  "load",
                  "vmpEsperada",
                  "vmpAtual",
                  "vmpAtual2",
                  "Salto Base",
                  "Salto PÓS",
                  "Salto PRÉ",
                  10,
                  <CustomTooltip />
                )}
              </Card>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col
              span={outerWidth < 501 ? 24 : 13}
              style={{ marginTop: "10px" }}
            >
              <Card
                id="card-table"
                style={{ minHeight: "500px" }}
                title={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <BicepsFlexed
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                        Bem Estar
                      </Text>
                    </div>
                  </div>
                }
                bordered={false}
              >
                <Table
                  columns={ColumnsUserWellBeing(deleteControl, editControl)}
                  dataSource={userControl.controlActual?.controls ?? []}
                  pagination={false}
                  size="small"
                  locale={{
                    emptyText: "Sem registro",
                  }}
                  scroll={{
                    y: 413,
                  }}
                />
              </Card>
            </Col>
            <Col
              span={outerWidth < 501 ? 24 : 11}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Gauge
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Bem Estar
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBarOne(
                  mountChartBarOne(),
                  "week",
                  "averageWellBeing",
                  "Média",
                  5
                )}
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <DrawerApp
        open={openResultPoint}
        onClose={onCloseResultPoint}
        title={"Score Bem Estar"}
        body={<FormScorePoint />}
        width={outerWidth < 501 ? "100%" : "30%"}
        isOnlyView={true}
      />
      <DrawerApp
        open={openControl}
        onClose={onClose}
        title={"Editar Controle"}
        body={formNewUserControl(formRef, imgAvatar)}
        size={"large"}
        actionSave={handleNewControl}
        width={outerWidth < 501 ? "100%" : "40%"}
        extra={
          <Button
            style={{
              fontFamily: FONT,
              background: "#14B8A6",
              color: "#ffffff",
            }}
            onClick={() => setOpenResultPoint(true)}
          >
            Score Bem Estar
          </Button>
        }
      />
    </>
  );
};
export default FormLoadUserComparation;
