import React, { useEffect, useState, useRef } from "react";

import {
  Avatar,
  Button,
  Col,
  Row,
  Spin,
  Tag,
  Timeline,
  Typography,
} from "antd";
import { CircleCheck, RefreshCcw, UserRound } from "lucide-react";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
import moment from "moment";
import { translationsTypes } from "../planning/columns-table";
const { Title, Paragraph, Text } = Typography;

const dateFormat = "DD/MM/YYYY";
import { FONT } from "constants/index";
import FormLoadUserComparation from "../load-control/load-control-drawer-comparation";
import DrawerApp from "components/drawer-app";

const TimelineBoot = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [imgAvatar, setImgAvatar] = useState("");
  const [trainings, setTrainings] = useState([]);
  const [openComparation, setOpenComparation] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    setImgAvatar(props.user.img);
    setTrainings(props.user.allTrainings);
    setLoadingData(false);
  }, [props]);

  function getTranslationTypeBoot(exercise) {
    return translationsTypes[exercise] ?? exercise;
  }
  function getTagBoot(typeExerciseItem) {
    const typeTrainingA = [
      "GOBLET_SQUAT",
      "HANG_CLEAN",
      "CONTINUOUS_JUMP",
      "SLDL_2_DUMBBELLS",
      "BENCH_BRIDGE_ONE_LEG",
    ];
    const typeTrainingB = [
      "SQUAT_FREE",
      "HANG_CLEAND_2T",
      "DEPTH_JUMP",
      "DEADLIFT",
      "BENCH_BRIDGE_TWO_LEGS",
    ];
    const typeTrainingC = [
      "FRONT_SQUAT",
      "SNATCH",
      "DROP_JUMP",
      "ROMANIAN_SURVEY",
    ];

    let typeTraining = "A";
    let color = "green";
    if (typeTrainingA.includes(typeExerciseItem)) {
      typeTraining = "A";
      color = "green";
    }
    if (typeTrainingB.includes(typeExerciseItem)) {
      typeTraining = "B";
      color = "geekblue";
    }
    if (typeTrainingC.includes(typeExerciseItem)) {
      typeTraining = "C";
      color = "yellow";
    }
    return (
      <div style={{ marginLeft: "3px" }}>
        {" - "}
        <Tag color={color} key={typeTraining}>
          {typeTraining.toUpperCase()}
        </Tag>
      </div>
    );
  }
  const onCloseComparation = () => {
    setOpenComparation(false);
  };
  return (
    <>
      <Spin spinning={loadingData}>
        <Row style={{ margin: 5 }} justify="end">
          <Button
            style={{
              fontFamily: FONT,
              background: "#14B8A6",
              color: "#ffffff",
            }}
            onClick={() => setOpenComparation(true)}
          >
            Controle de Carga
          </Button>
        </Row>
        <Row gutter={16} style={{ textAlign: "center", marginBottom: "30px" }}>
          <Col span={24}>
            <Avatar
              src={imgAvatar}
              icon={<UserRound size={120} />}
              size={120}
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ textAlign: "center", marginBottom: "30px" }}>
          <Col span={24}>
            <Text strong style={{ fontFamily: FONT }}>
              {props.user.name}
            </Text>
          </Col>
        </Row>

        <Timeline mode="alternate" reverse={true} style={{ fontFamily: FONT }}>
          {trainings.map((item, index) => (
            <Timeline.Item
              color={item.isDone == true ? "green" : "blue"}
              dot={
                item.isDone == true ? (
                  <CircleCheck size={16} />
                ) : (
                  <RefreshCcw size={16} />
                )
              }
            >
              <div>
                {item.isDone == true
                  ? "Treino dia " + moment(item.updatedAt).format(dateFormat)
                  : "Treino Atual"}
              </div>
              <div>
                <Title
                  style={{ textAlign: "left", fontFamily: FONT }}
                  level={5}
                >
                  Exercicios:
                </Title>
                <Paragraph style={{ textAlign: "left", fontFamily: FONT }}>
                  <ul>
                    <li>
                      <div>
                        <Text style={{ fontFamily: FONT }}>
                          {getTranslationTypeBoot(item.typeExercise1)}
                        </Text>
                      </div>
                      <div>
                        <Text strong style={{ fontFamily: FONT }}>
                          Fase:
                        </Text>{" "}
                        <div style={{ display: "inline-flex" }}>
                          {item.step1}
                          {item.step1 == 4 && getTagBoot(item.typeExercise1)}
                        </div>
                      </div>
                      {item.loadExecuted1 && (
                        <div>
                          <Text strong style={{ fontFamily: FONT }}>
                            Carga:
                          </Text>{" "}
                          {item.loadExecuted1} Kg
                        </div>
                      )}
                      <div>
                        <Text>
                          <Text strong style={{ fontFamily: FONT }}>
                            Semanas:
                          </Text>{" "}
                          {item.load1}
                        </Text>
                      </div>
                    </li>
                    <li>
                      <div>
                        <Text style={{ fontFamily: FONT }}>
                          {getTranslationTypeBoot(item.typeExercise2)}
                        </Text>
                      </div>
                      <div>
                        <Text strong style={{ fontFamily: FONT }}>
                          Fase:
                        </Text>{" "}
                        <div style={{ display: "inline-flex" }}>
                          {item.step2}
                          {item.step2 == 4 && getTagBoot(item.typeExercise2)}
                        </div>
                      </div>
                      {item.loadExecuted2 && (
                        <div>
                          <Text strong style={{ fontFamily: FONT }}>
                            Carga:
                          </Text>{" "}
                          {item.loadExecuted2} Kg
                        </div>
                      )}
                      <div>
                        <Text>
                          <Text strong style={{ fontFamily: FONT }}>
                            Semanas:
                          </Text>{" "}
                          {item.load2}
                        </Text>
                      </div>
                    </li>
                    <li>
                      <div>
                        <Text style={{ fontFamily: FONT }}>
                          {getTranslationTypeBoot(item.typeExercise3)}
                        </Text>
                      </div>
                      <div>
                        <Text>
                          <Text strong style={{ fontFamily: FONT }}>
                            Fase:
                          </Text>{" "}
                          <div style={{ display: "inline-flex" }}>
                            {item.step3}
                            {item.step3 == 4 && getTagBoot(item.typeExercise3)}
                          </div>
                        </Text>
                      </div>
                      {item.loadExecuted3 && (
                        <div>
                          <Text strong style={{ fontFamily: FONT }}>
                            Carga:
                          </Text>{" "}
                          {item.loadExecuted3} Kg
                        </div>
                      )}
                      <div>
                        <Text>
                          <Text strong style={{ fontFamily: FONT }}>
                            Semanas:
                          </Text>{" "}
                          {item.load3}
                        </Text>
                      </div>
                    </li>
                    <li>
                      <div>
                        <Text style={{ fontFamily: FONT }}>
                          {getTranslationTypeBoot(item.typeExercise4)}
                        </Text>
                      </div>
                      <div>
                        <Text>
                          <Text strong style={{ fontFamily: FONT }}>
                            Fase:
                          </Text>{" "}
                          <div style={{ display: "inline-flex" }}>
                            {item.step4}
                            {item.step4 == 4 && getTagBoot(item.typeExercise4)}
                          </div>{" "}
                        </Text>
                      </div>
                      {item.loadExecuted4 && (
                        <div>
                          <Text strong style={{ fontFamily: FONT }}>
                            Carga:
                          </Text>{" "}
                          {item.loadExecuted4} Kg
                        </div>
                      )}
                      <div>
                        <Text>
                          <Text strong style={{ fontFamily: FONT }}>
                            Semanas:
                          </Text>{" "}
                          {item.load4}
                        </Text>
                      </div>
                    </li>
                  </ul>
                </Paragraph>
                {item.annotation && (
                  <div style={{ textAlign: "left", fontFamily: FONT }}>
                    <Title level={5} style={{ fontFamily: FONT }}>
                      Anotações:
                    </Title>
                    <Text style={{ fontFamily: FONT }}>{item.annotation}</Text>
                  </div>
                )}
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </Spin>
      <DrawerApp
        open={openComparation}
        onClose={onCloseComparation}
        title={"Histórico"}
        body={<FormLoadUserComparation userId={props.user?.id} isBoot={true} />}
        size={"large"}
        width={outerWidth < 501 ? "100%" : "90%"}
        isOnlyView={true}
      />
    </>
  );
};
export default TimelineBoot;
