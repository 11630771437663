import { Avatar, Popover, Space, Tag, Tooltip } from "antd";
import { FONT } from "constants/index";
import {
  ArrowDownLeft,
  ChartColumnStacked,
  SquareChartGantt,
  UserRound,
  UserRoundMinus,
  UserRoundPen,
  UserRoundX,
} from "lucide-react";
import moment from "moment";

export const ColumnsUser = (isBoot) => {
  const table = [
    {
      title: "Foto",
      dataIndex: "image",
      width: "10%",
      render: (text, row) => {
        return (
          <Avatar src={row.img} icon={<UserRound size={50} />} size={50} />
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      textWrap: "word-break",
      ellipsis: true,
    },
  ];

  const tableDefault = [
    {
      title: "E-mail",
      dataIndex: "email",
      textWrap: "word-break",
      ellipsis: true,
    },
  ];

  if (isBoot != true) {
    tableDefault.push({
      title: "Última Avaliação",
      dataIndex: "reviewDate",
      textWrap: "word-break",
      width: "15%",
      ellipsis: true,
      render: (text, row) => {
        if (row.lastReview?.reviewDate) {
          return moment(row.lastReview?.reviewDate).format("DD/MM/YYYY");
        } else {
          return "";
        }
      },
    });
  }
  if (outerWidth > 501) {
    table.push(...tableDefault);
  }

  table.push({
    title: "Ações",
    dataIndex: "action",
    width: "15%",
    render: (text, row) => {
      return (
        <Space size="middle">
          <Tooltip style={{ fontFamily: FONT }} title="Novo Controle">
            <SquareChartGantt
              onClick={() => row.edit(row)}
              size={22}
              style={{ cursor: "pointer", color: "#09ac9c" }}
            />
          </Tooltip>

          <Tooltip style={{ fontFamily: FONT }} title="Histórico">
            <ChartColumnStacked
              onClick={() => row.comparation(row)}
              size={22}
              style={{ cursor: "pointer", color: "#2980B9" }}
            />
          </Tooltip>
        </Space>
      );
    },
  });
  return table;
};

export const ColumnsUserComparation = () => {
  const table = [
    {
      title: "Ciclo",
      dataIndex: "reviewDate",
      textWrap: "word-break",
      ellipsis: true,
      render: (text, row) => {
        if (text) {
          return moment(text).format("DD/MM/YYYY");
        } else {
          return "";
        }
      },
    },
    {
      title: "Salto Base",
      dataIndex: "cmj",
    },
  ];

  table.push({
    title: "Ações",
    dataIndex: "action",
    render: (text, row) => {
      return (
        <Space size="middle">
          <Tooltip
            style={{ fontFamily: FONT }}
            title="Selecionar como atual controle"
          >
            <ArrowDownLeft
              onClick={() => row.changeActualComp(row)}
              size={22}
              style={{ cursor: "pointer", color: "#09ac9c" }}
            />
          </Tooltip>
        </Space>
      );
    },
  });
  return table;
};

const contentScoreDrop = (
  <div>
    <p>
      Queda ≤ 2% → Excelente – Atleta recuperado e pronto para treinar em alta
      intensidade
    </p>
    <p>
      Queda entre 2% e 5% → Bom – Atleta recuperado, mas pode precisar de
      pequenos ajustes no volume
    </p>
    <p>
      Queda entre 5% e 10% → Moderado – Indica sinais de fadiga; pode ser
      necessário reduzir o volume do treinamento
    </p>
    <p>
      Queda entre 10% e 15% → Ruim – Indica cansaço acumulado; sugere
      necessidade de um treino estabilizador ou ajuste no volume e na
      intensidade do treino
    </p>
    <p>
      Queda superior a 15% → Crítico – Indica risco de overtraining; é
      recomendado descanso completo ou um ajuste profundo na carga de
      treinamento
    </p>
  </div>
);

const contentScoreWellBeing = (
  <div>
    <p>
      4,5 a 5,0 → Excelente – Atleta recuperado e pronto para treinar em alta
      intensidade.
    </p>
    <p>
      3,5 a 4,4 → Bom – Atleta recuperado, mas pode precisar de ajustes menores
      na carga de treino.
    </p>
    <p>
      2,5 a 3,4 → Moderado – Indica sinais de fadiga; pode ser necessário
      reduzir a carga ou fazer um treino mais leve.
    </p>
    <p>
      1,5 a 2,4 → Ruim – Indica cansaço acumulado; sugere necessidade de
      descanso ativo ou ajuste significativo na carga.
    </p>
    <p>
      1,0 a 1,4 → Crítico – Indica risco de overtraining; é recomendado descanso
      completo ou um ajuste profundo no ciclo de treino.
    </p>
  </div>
);
export const ColumnsUserCmj = (deleteControl, editControl) => {
  const table = [
    {
      title: "Semana",
      dataIndex: "week",
      width: "15%",

      render: (text, row, index) => {
        const typeTraining = index % 2 !== 0 ? "B" : "A";
        const color = index % 2 !== 0 ? "geekblue" : "green";

        return (
          <div>
            Semana -{" "}
            <Tag color={color} key={typeTraining}>
              {typeTraining.toUpperCase()}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Data",
      dataIndex: "date",
      textWrap: "word-break",
      ellipsis: true,
      render: (text, row) => {
        if (text) {
          return moment(text).format("DD/MM/YYYY");
        } else {
          return "";
        }
      },
    },
    {
      title: "CMJ PRÉ",
      dataIndex: "cmjFirst",
    },

    {
      title: "% Queda PRÉ",
      dataIndex: "cmjFirstDrop",
      render: (text) => {
        let color = "green";
        if (text < -10) {
          color = "red";
        }
        if (text <= -6 && text >= -10) {
          color = "yellow";
        }

        return (
          <Popover content={contentScoreDrop} title="Valores de Queda">
            <div>
              <Tag color={color}>{text}</Tag>
            </div>
          </Popover>
        );
      },
    },
    {
      title: "CMJ PÓS",
      dataIndex: "cmjSecond",
    },

    {
      title: "% Queda PÓS",
      dataIndex: "cmjSecondDrop",
      render: (text) => {
        let color = "green";
        if (text < -10) {
          color = "red";
        }
        if (text <= -6 && text >= -10) {
          color = "yellow";
        }

        return (
          <div>
            <Tag color={color}>{text}</Tag>
          </div>
        );
      },
    },
    {
      title: "Ações",
      dataIndex: "action",
      render: (text, row) => {
        return (
          <Space size="middle">
            <Tooltip style={{ fontFamily: FONT }} title="Editar Controle">
              <UserRoundPen
                onClick={() => editControl(row)}
                size={22}
                style={{ cursor: "pointer", color: "#09ac9c" }}
              />
            </Tooltip>

            <Tooltip style={{ fontFamily: FONT }} title="Deletar Controle">
              <UserRoundX
                onClick={() => deleteControl(row)}
                size={22}
                style={{ cursor: "pointer", color: "#EC6D62" }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return table;
};
export const ColumnsUserWellBeing = (deleteControl, editControl) => {
  const table = [
    {
      title: "Semana",
      dataIndex: "week",
      width: "15%",
      render: (text, row, index) => {
        const typeTraining = index % 2 !== 0 ? "B" : "A";
        const color = index % 2 !== 0 ? "geekblue" : "green";

        return (
          <div>
            Semana -{" "}
            <Tag color={color} key={typeTraining}>
              {typeTraining.toUpperCase()}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Data",
      dataIndex: "date",
      textWrap: "word-break",
      ellipsis: true,
      render: (text, row) => {
        if (text) {
          return moment(text).format("DD/MM/YYYY");
        } else {
          return "";
        }
      },
    },
    {
      title: "Fadiga",
      dataIndex: "fatigue",
    },

    {
      title: "Dor",
      dataIndex: "pain",
    },
    {
      title: "Humor",
      dataIndex: "humor",
    },

    {
      title: "Estresse",
      dataIndex: "stress",
    },
    {
      title: "Sono",
      dataIndex: "sleep",
    },
    {
      title: "Média",
      dataIndex: "averageWellBeing",
      render: (value) => {
        let color = "green";
        if (value < 1.5) {
          color = "red";
        }
        if (value < 2.5 && value >= 1.5) {
          color = "orange";
        }
        if (value < 3.5 && value >= 2.5) {
          color = "yellow";
        }
        if (value < 4.5 && value >= 3.5) {
          color = "blue";
        }
        return (
          <Popover content={contentScoreWellBeing} title="Interpretação Score">
            <div>
              <Tag color={color}>{value}</Tag>
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Ações",
      dataIndex: "action",
      render: (text, row) => {
        return (
          <Space size="middle">
            <Tooltip style={{ fontFamily: FONT }} title="Editar Controle">
              <UserRoundPen
                onClick={() => editControl(row)}
                size={22}
                style={{ cursor: "pointer", color: "#09ac9c" }}
              />
            </Tooltip>

            <Tooltip style={{ fontFamily: FONT }} title="Deletar Controle">
              <UserRoundX
                onClick={() => deleteControl(row)}
                size={22}
                style={{ cursor: "pointer", color: "#EC6D62" }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return table;
};
