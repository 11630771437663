import React, { useEffect, useState } from "react";

import {
  Row,
  Input,
  Typography,
  Form,
  Col,
  DatePicker,
  Divider,
  Avatar,
  Space,
  Card,
  Table,
  Spin,
} from "antd";
import {
  ArrowUpFromLine,
  Medal,
  UserRound,
} from "lucide-react";
import { FONT } from "constants/index";
import { ColumnsUserComparation } from "./columns-table";
import { chartBar } from "./reviews-drawer-charts";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
const { Text } = Typography;
const dateFormat = "DD/MM/YYYY";
const FormUserComparationBoot = (props) => {
  const [data, setDataDrawer] = useState({ reviews: [] });
  const [imgAvatar, setImgAvatar] = useState(null);
  const formRefComp = React.useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const data = props.dataDrawer;
    data.reviews = data?.controlGrouped?.map((obj) => ({
      ...obj,
      changeActualComp: changeActual,
      changeLastComp: changeLast,
    }));

    setImgAvatar(props.imgAvatar);

    const { controlGrouped } = props.dataDrawer || {};

    const reviewActual =
      controlGrouped &&
      controlGrouped.length > 0 &&
      controlGrouped[0]?.reviewDate
        ? dayjs(controlGrouped[0].reviewDate)
        : null;

    const reviewComparation =
      controlGrouped &&
      controlGrouped.length > 1 &&
      controlGrouped[1]?.reviewDate
        ? dayjs(controlGrouped[1].reviewDate)
        : null;

    data.actualReview =
      controlGrouped &&
      controlGrouped.length > 0 &&
      controlGrouped[0]?.reviewDate
        ? controlGrouped[0]
        : null;
    data.lastReview =
      controlGrouped &&
      controlGrouped.length > 0 &&
      controlGrouped[1]?.reviewDate
        ? controlGrouped[1]
        : null;
    setDataDrawer(data);

    formRefComp.current?.setFieldsValue({
      name: props.dataDrawer.name,
      reviewActual: reviewActual,
      reviewComparation: reviewComparation,
    });
  }, [props, setLoading]);

  const changeActual = function (row, data) {
    setLoading(true);
    setTimeout(() => {
      data.actualReview = row;
      formRefComp.current?.setFieldsValue({
        reviewActual: row.reviewDate ? dayjs(row.reviewDate) : null,
      });
      setDataDrawer(data);
      setLoading(false);
    }, 300);
  };

  const changeLast = function (row, data) {
    setLoading(true);
    setTimeout(() => {
      data.lastReview = row;
      formRefComp.current?.setFieldsValue({
        reviewComparation: row.reviewDate ? dayjs(row.reviewDate) : null,
      });
      setDataDrawer(data);
      setLoading(false);
    }, 300);
  };

  return (
    <>
      <Spin size="large" spinning={loading}>
        <Form ref={formRefComp} layout="vertical">
          <Row
            gutter={16}
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            <Col span={24}>
              <Avatar
                src={imgAvatar}
                icon={<UserRound size={120} />}
                size={120}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Space direction="vertical" size={"small"}>
              <Card
                id="card-table"
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Medal
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                        Histórico de Ciclos
                      </Text>
                    </div>
                  </div>
                }
                bordered={false}
              >
                <Table
                  columns={ColumnsUserComparation(data)}
                  dataSource={data.reviews}
                  pagination={{ pageSize: 2, showSizeChanger: false }}
                  locale={{
                    emptyText: "Sem registro",
                  }}
                />
              </Card>
            </Space>
          </Row>
          <Divider />

          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Form.Item
                name="name"
                label={<span style={{ fontFamily: FONT }}>Aluno</span>}
              >
                <Input
                  disabled={true}
                  style={{ fontFamily: FONT }}
                  defaultValue={data.name}
                />
              </Form.Item>
            </Col>
            <Col span={outerWidth < 501 ? 24 : 6} style={{ marginTop: "10px" }}>
              <Form.Item
                name="reviewActual"
                label={
                  <span style={{ fontFamily: FONT }}>Ciclo Atual</span>
                }
                rules={[
                  {
                    required: true,
                    message: "É necessário informar a data da avaliação",
                  },
                ]}
              >
                <DatePicker
                  style={{ fontFamily: FONT, width: "100%" }}
                  placeholder={["", ""]}
                  format={dateFormat}
                  suffixIcon={null}
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col span={outerWidth < 501 ? 24 : 6} style={{ marginTop: "10px" }}>
              <Form.Item
                name="reviewComparation"
                label={
                  <span style={{ fontFamily: FONT }}>
                    Ciclo de Comparação
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "É necessário informar a data da avaliação",
                  },
                ]}
              >
                <DatePicker
                  style={{ fontFamily: FONT, width: "100%" }}
                  placeholder={["", ""]}
                  format={dateFormat}
                  suffixIcon={null}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row gutter={16}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Text
                style={{
                  fontSize: "15px",
                  fontFamily: FONT,
                }}
              >
                Saltos
              </Text>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col
              span={outerWidth < 501 ? 24 : 24}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowUpFromLine
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Counter Movement Jump - CMJ
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump: null,
                      jumpActual: data?.actualReview?.cmj,
                      jumpComparation: data?.lastReview?.cmj,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "CMJ Atual",
                  "CMJ Comparação",
                  10
                )}
              </Card>
            </Col>
          </Row>

          <Divider />
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowUpFromLine
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Sprint Linear
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump:
                        data?.actualReview?.sprintLinearDate +
                        " - " +
                        data?.lastReview?.sprintLinearDate,
                      jumpActual: data?.actualReview?.sprintLinear,
                      jumpComparation: data?.lastReview?.sprintLinear,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "Linear Atual",
                  "Linear Comparação",
                  10
                )}
              </Card>
            </Col>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowUpFromLine
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Cod Sprint
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump:
                        data?.actualReview?.codSprintDate +
                        " - " +
                        data?.lastReview?.codSprintDate,
                      jumpActual: data?.actualReview?.codSprint,
                      jumpComparation: data?.lastReview?.codSprint,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "Cod Atual",
                  "Cod Comparação",
                  10
                )}
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};
export default FormUserComparationBoot;
