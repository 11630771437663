import {
  Row,
  Input,
  Typography,
  Form,
  Col,
  DatePicker,
  InputNumber,
  Divider,
  Avatar,
  Slider,
  Button,
} from "antd";
import { UserRound } from "lucide-react";
import React, { useState } from "react";

import { FONT } from "constants/index";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DrawerApp from "components/drawer-app";
import FormScorePoint from "./load-control-drawer-score-point";
dayjs.extend(customParseFormat);

const { Text } = Typography;
const dateFormat = "DD/MM/YYYY";

export const formNewUserControl = (formRef, imgAvatar) => {
  const [openResultPoint, setOpenResultPoint] = useState(false);
  const onCloseResultPoint = () => {
    setOpenResultPoint(false);
  };
  const marks = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
  };
  return (
    <>
      <Form ref={formRef} layout="vertical">
        <Row gutter={16} style={{ textAlign: "center", marginBottom: "30px" }}>
          <Col span={24}>
            <Avatar
              src={imgAvatar}
              icon={<UserRound size={120} />}
              size={120}
            />
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button
              style={{
                fontFamily: FONT,
                background: "#14B8A6",
                color: "#ffffff",
              }}
              onClick={() => setOpenResultPoint(true)}
            >
              Score Bem Estar
            </Button>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={<span style={{ fontFamily: FONT }}>Aluno</span>}
            >
              <Input disabled={true} style={{ fontFamily: FONT }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="date"
              label={<span style={{ fontFamily: FONT }}>Data</span>}
              rules={[
                {
                  required: true,
                  message: "É necessário informar a data da avaliação",
                },
              ]}
            >
              <DatePicker
                style={{ fontFamily: FONT, width: "100%" }}
                placeholder={["", ""]}
                format={dateFormat}
                disabled={true}
                suffixIcon={null}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Text
              style={{
                fontSize: "15px",
                fontFamily: FONT,
              }}
            >
              Saltos
            </Text>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={12}>
            <Form.Item
              name="cmjFirst"
              label={<span style={{ fontFamily: FONT }}>CMJ Pré</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="cmjSecond"
              label={<span style={{ fontFamily: FONT }}>CMJ Pós</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Text
              style={{
                fontSize: "15px",
                fontFamily: FONT,
              }}
            >
              Bem Estar
            </Text>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={24}>
            <Form.Item
              name="fatigue"
              label={<span style={{ fontFamily: FONT }}>Fadiga</span>}
            >
              <Slider
                marks={marks}
                min={1}
                max={5}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={24}>
            <Form.Item
              name="pain"
              label={<span style={{ fontFamily: FONT }}>Dor</span>}
            >
              <Slider
                marks={marks}
                min={1}
                max={5}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={24}>
            <Form.Item
              name="humor"
              label={<span style={{ fontFamily: FONT }}>Humor</span>}
            >
              <Slider
                marks={marks}
                min={1}
                max={5}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={24}>
            <Form.Item
              name="stress"
              label={<span style={{ fontFamily: FONT }}>Estresse</span>}
            >
              <Slider
                marks={marks}
                min={1}
                max={5}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={24}>
            <Form.Item
              name="sleep"
              label={<span style={{ fontFamily: FONT }}>Sono</span>}
            >
              <Slider
                marks={marks}
                min={1}
                max={5}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <DrawerApp
        open={openResultPoint}
        onClose={onCloseResultPoint}
        title={"Score Bem Estar"}
        body={<FormScorePoint />}
        width={outerWidth < 501 ? "100%" : "50%"}
        isOnlyView={true}
      />
    </>
  );
};
