import { Space, Tag, Tooltip } from "antd";
import { Edit, Info } from "lucide-react";
import { FONT } from "constants/index";

export const ColumnsChart = () => {
  const table = [
    {
      title: "Semana",
      dataIndex: "load",
      width: "15%",
      render: (text, row) => {
        if (row.annotation) {
          return (
            <div>
              {text}
              <Info
                color="#f7e96d"
                style={{
                  marginBottom: "-6px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={() => row.openAnotation(row.annotation)}
              />
            </div>
          );
        }
        return text;
      },
    },
    {
      title: "Treino",
      dataIndex: "orderTraining",
      width: "15%",
      render: (text) => {
        const typeTraining = text % 2 === 0 ? "B" : "A";
        const color = text % 2 === 0 ? "geekblue" : "green";

        return (
          <Tag color={color} key={typeTraining}>
            {typeTraining.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "VMP 1",
      dataIndex: "vmp1",
      width: "15%",
    },
    {
      title: "VMP 2",
      dataIndex: "vmp2",
      width: "15%",
    },
    {
      title: "VMP 3",
      dataIndex: "vmp3",
      width: "15%",
    },
    {
      title: "VMP 4",
      dataIndex: "vmp4",
      width: "15%",
    },
    {
      title: "Ações",
      dataIndex: "action",
      width: "25%",
      render: (text, row) => {
        if (row.isDone == true) {
          return (
            <Space size="middle">
              <Tooltip style={{ fontFamily: FONT }} title="Editar Treino">
                <Edit
                  onClick={() => row.edit(row)}
                  size={22}
                  style={{ cursor: "pointer", color: "#2980B9" }}
                />
              </Tooltip>
            </Space>
          );
        }
      },
    },
  ];

  return table;
};
