import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import { USER_STORAGE, ROLE_USER } from "constants/index";
import { useSelector } from "react-redux";
import { selectRole } from "reducer/login/login-user";
import { Menu as MenuIcon, Trophy } from "lucide-react";
import {
  Settings,
  ChartCandlestick,
  CircleDollarSign,
  Users,
  Gauge,
  ClipboardCheck,
  Calendar,
  Dumbbell,
} from "lucide-react";
import { GetPropsMenu } from "./menu-custom";

const { Sider } = Layout;
const LOGO = require("images/login/logo-unique-branco.png");

const MenuApp = () => {
  const navigate = useNavigate();
  const [roleRedux, setRoleRedux] = useState(useSelector(selectRole));
  const [collapsed, setCollapsed] = useState(false);
  const customMenu = GetPropsMenu(location.pathname);
  const [collapsedIcon, setCollapsedIcon] = useState({
    dash: null,
    financial: null,
    financialAdm: null,
    setting: null,
  });

  useEffect(() => {
    const localStorageItem = localStorage.getItem(USER_STORAGE);
    if (localStorageItem != null && roleRedux == "") {
      const { role } = JSON.parse(localStorageItem);
      setRoleRedux(role);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1350) {
        // ajuste o valor conforme necessário
        setCollapsed(true);
        setCollapsedIcon({
          dash: <ChartCandlestick size={20} style={{ marginLeft: "20px" }} />,
          financial: <Settings size={20} style={{ marginLeft: "20px" }} />,
          setting: <Settings size={20} style={{ marginLeft: "20px" }} />,
          financialAdm: (
            <CircleDollarSign size={20} style={{ marginLeft: "20px" }} />
          ),
        });
      } else {
        setCollapsed(false);
        setCollapsedIcon({
          dash: null,
          financial: null,
          setting: null,
          financialAdm: null,
        });
      }
    };

  
    window.addEventListener("resize", handleResize);

    // Chame a função uma vez para definir o estado inicial
    handleResize();

    // Limpe o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleResize);

    };
  }, []);

  function getItem(label, key, icon, children, type, router) {
    return {
      key,
      icon,
      children,
      label,
      type,
      onClick: () => {
        if (router) navigate(router);
      },
    };
  }
  const itemsAdm = [
    getItem("Academia", "sub1", collapsedIcon.dash, [
      getItem(
        "Painel Gerencial",
        "1",
        <Gauge size={15} />,
        null,
        null,
        "/app/client/managerial"
      ),
      getItem(
        "Avaliações",
        "2",
        <ClipboardCheck size={15} />,
        null,
        null,
        "/app/client/reviews"
      ),

      getItem(
        "Planificação",
        "4",
        <Calendar size={15} />,
        null,
        null,
        "/app/client/planning"
      ),
      getItem(
        "Controle de Cargas",
        "5",
        <Dumbbell size={15} />,
        null,
        null,
        "/app/client/load-control"
      ),
      getItem(
        "Classificação",
        "7",
        <Trophy size={15} />,
        null,
        null,
        "/app/client/ranking"
      ),
      getItem(
        "Treinos",
        "8",
        <Dumbbell size={15} />,
        null,
        null,
        "/app/client/trainning"
      ),
    ]),
    getItem("Cadastros", "sub2", collapsedIcon.financial, [
      getItem(
        "Usuários",
        "6",
        <Users size={15} />,
        null,
        null,
        "/app/client/users"
      ),
    ]),
  ];

  const itemsUser = [
    getItem("Academia", "sub1", collapsedIcon.dash, [
      getItem(
        "Painel Gerencial",
        "1",
        <Gauge size={15} />,
        null,
        null,
        "/app/client/managerial"
      ),
      getItem(
        "Avaliações",
        "2",
        <ClipboardCheck size={15} />,
        null,
        null,
        "/app/client/reviews"
      ),

      getItem(
        "Planificação",
        "4",
        <Calendar size={15} />,
        null,
        null,
        "/app/client/planning"
      ),
      getItem(
        "Controle de Cargas",
        "5",
        <Dumbbell size={15} />,
        null,
        null,
        "/app/client/load-control"
      ),
      getItem(
        "Classificação",
        "7",
        <Trophy size={15} />,
        null,
        null,
        "/app/client/ranking"
      ),
    
    ]),
  ];
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    if (!collapsed == true) {
      setCollapsedIcon({
        dash: <ChartCandlestick size={20} style={{ marginLeft: "20px" }} />,
        financial: <Settings size={20} style={{ marginLeft: "20px" }} />,
        setting: <Settings size={20} style={{ marginLeft: "20px" }} />,
        financialAdm: (
          <CircleDollarSign size={20} style={{ marginLeft: "20px" }} />
        ),
      });
    } else {
      setCollapsedIcon({
        dash: null,
        financial: null,
        setting: null,
        financialAdm: null,
      });
    }
  };
  if (outerWidth < 501) {
    return (
      <Menu
        mode="horizontal"
        theme="dark"
        defaultSelectedKeys={[customMenu.defaultSelectedKeys]}
        items={roleRedux == ROLE_USER ? itemsUser : itemsAdm}
      />
    );
  }

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => toggleCollapsed()}
      className="container-sider"
      id="siderMenu"
      width={"15%"}
      trackStyle={{ backgroundColor: "#12475c" }}
    >
      <div className="top-menu">
        <div
          onClick={toggleCollapsed}
          style={{
            marginBottom: 16,
            cursor: "pointer",
            color: "#ffffff",
            textAlign: "end",
            paddingRight: "15px",
          }}
        >
          {collapsed ? <MenuIcon size={20} /> : <MenuIcon size={20} />}
        </div>
        {!collapsed && <img className="logo-login" src={LOGO} alt="Logo" />}
      </div>

      <div className="body-menu">
        <Menu
          mode="inline"
          theme="dark"
          defaultOpenKeys={["sub1", "sub2"]}
          defaultSelectedKeys={[customMenu.defaultSelectedKeys]}
          items={roleRedux == ROLE_USER ? itemsUser : itemsAdm}
        />
      </div>
    </Sider>
  );
};

export default MenuApp;
