import { Col, Divider, Row } from "antd";
import React from "react";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">
      {title} {content}
    </p>
  </div>
);

const FormTrainningBoot = () => {
  return (
    <div>
      <div style={{ marginTop: "20px" }}>
        <div>
          <p className="site-description-item-profile-p">FASE 1</p>

          <Row>
            <Col span={24}>
              <DescriptionItem title="Agachamento com contrabalanço e apoio" content="" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Agachamento frontal com braços livres"
                content=""
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem title="Aterrissagem" content="" />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Desassociação lombopélvica"
                content=""
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Elevação pélvica com 2 pernas"
                content=""
              />
            </Col>
          </Row>
          <Divider />

          <p className="site-description-item-profile-p">FASE 2</p>

          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Agachamento com contrabalanço"
                content=""
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem title="Agachamento frontal" content="" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem title="Salto sem contramovimento" content="" />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Alcance no cone na diagonal"
                content=""
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Elevação pélvica com 1 perna"
                content=""
              />
            </Col>
          </Row>
          <Divider />
          <p className="site-description-item-profile-p">FASE 3</p>

          <Row>
            <Col span={24}>
              <DescriptionItem title="Agachamento livre" content="" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem title="Clean High Pull" content="" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem title="Salto com contramovimento" content="" />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Alcance de braços livres"
                content=""
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Elevação pélvica no banco com 2 pernas"
                content=""
              />
            </Col>
          </Row>
          <Divider />
          <p className="site-description-item-profile-p">FASE 4</p>

          <Row>
            <Col span={24}>
              <DescriptionItem title="Agachamento Goblet" content="" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem title="Hang Clean" content="" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem title="Salto contínuo" content="" />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Alcance com peso"
                content=""
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Elevação pélvica no banco com 1 perna"
                content=""
              />
            </Col>
          </Row>
          <Divider />
          <p className="site-description-item-profile-p">VARIAÇÕES FASE 4</p>

          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Agachamento"
                content="(Back Squat ou Front Squat)"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Pliométrico"
                content="(Depth Jump ou Drop Jump)"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Levantamento olímpico"
                content="(Hang Clean 2T ou Snatch)"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Dominante de quadril – pernas estendidas"
                content="(Deadlift ou Levantamento Romeno)"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title="Dominante de quadril – pernas flexionadas"
                content="(Elevação no banco com 2 pernas e barra)"
              />
            </Col>
          </Row>
          <Divider />
        </div>
      </div>
    </div>
  );
};

export default FormTrainningBoot;
