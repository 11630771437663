import React from "react";
import { Tabs } from "antd";
import { FONT } from "constants/index";

import Trainning from "./trainning";
import TrainningBoot from "./trainning-boot";

const TrainningClient = () => {
  const items = [
    {
      label: `Método Unique`,
      key: "metodo",
      children: <Trainning />,
    },
    {
      label: `Iniciação`,
      key: "boot",
      children: <TrainningBoot />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey="1"
      centered
      size="large"
      style={{ fontFamily: FONT }}
      items={items}
    />
  );
};
export default TrainningClient;
