import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import {
  ChartColumnStacked,
  Check,
  CircleAlert,
  Edit,
  NotepadText,
  Plus,
  RotateCw,
  StickyNote,
  Trash2,
  UserRound,
} from "lucide-react";
import {
  getUsersPlanning,
  getUsersPlanningFinishTraining,
  getUsersPlanningToTraining,
  saveAnnotation,
  saveTraining,
} from "services/planning/planning-service";
import {
  notificationErroInternal,
  notificationInfo,
  notificationSucessMsg,
} from "util/api-utils";
import { translationsTypes } from "../planning/columns-table";
import { FONT } from "constants/index";
import DrawerApp from "components/drawer-app";
import PlanningExercises from "../planning/planning-drawer-exercises";
import FormNewUserPlanningExercise from "../planning/planning-drawer-new-exercise";
import ManagerialChart from "./managerial-drawer-chart";
import { ListEmptyState } from "views/util/emptyState";
import moment from "moment";
import FormNewUserReview from "../reviews/reviews-drawer-new-review";
import FormNewUserPlanning from "../planning/planning-drawer-new-planning";
import {
  closeTraining,
  getUsersBoot,
  getUsersBootToTraining,
  openTrainingBoot,
  saveAnnotationBoot,
  saveTrainingBoot,
} from "services/boot/boot-service";
import TimelineBoot from "./managerial-timeline-boot";
import ChangeBoot from "./managerial-change-boot";
import FormTrainningBoot from "../trainning/trainning-drawer-boot";

const { Meta } = Card;
const { confirm } = Modal;
const { TextArea } = Input;
const Managerial = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [openChart, setOpenChart] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [userTrainer, setUserTrainer] = useState(null);
  const [openDetailItem, setOpenDetailItem] = useState(false);
  const [exercise, setExercise] = useState(null);
  const [formOpenUser] = Form.useForm();
  const [dataPlanning, setDataPlanning] = useState([]);
  const [trainingFinish, setTrainingFinish] = useState([]);
  const [openTrainingFinish, setOpenTrainingFinish] = useState(false);
  const [openChartFinish, setOpenChartFinish] = useState(false);
  const [openReview, setOpenReview] = useState({
    open: false,
    edit: false,
  });
  const [openPlaning, setOpenPlaning] = useState(false);
  const [formAnnotation] = Form.useForm();
  const [dataBoot, setDataBoot] = useState([]);
  const [dataInitialBoot, setDataInitialBoot] = useState([]);
  const [openProgress, setOpenProgress] = useState(false);
  const [userTrainerBoot, setUserTrainerBoot] = useState(null);
  const [formDoneBoot] = Form.useForm();
  const [openChangeTraining, setOpenChangeTraining] = useState(false);
  const [openDetailBoot, setOpenDetailBoot] = useState(false);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    getAll();
  };
  const onCloseDetailBoot = () => {
    setOpenDetailBoot(false);
  };

  async function getAll() {
    try {
      setLoading(true);

      setLastUpdated(Date.now());
      await Promise.all([
        getData(),
        getDataPlanning(),
        getDataFinishTraning(),
        getDataBootTraining(),
        getDataBoot(),
      ]);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  }

  const onCloseDetail = () => {
    setOpenDetail(false);
  };
  const onCloseChangeTraining = () => {
    setOpenChangeTraining(false);
    refreshData();
  };

  const onClosePlaning = () => {
    getDataFinishTraning();
    setOpenPlaning(false);
  };

  const onCloseFinishTraining = () => {
    setOpenTrainingFinish(false);
  };

  const onCloseChart = () => {
    refreshData();
    setOpenChart(false);
  };

  const onCloseChartFinish = () => {
    refreshData();

    setOpenChartFinish(false);
  };
  const onCloseReview = () => {
    getDataFinishTraning();
    setOpenReview({ open: false, edit: false });
  };
  const onCloseProgress = () => {
    setOpenProgress(false);
  };
  async function getDataPlanning() {
    let obj = {
      onlyHasPlan: true,
    };
    await getUsersPlanning(obj)
      .then((res) => {
        setDataPlanning(res);
      })
      .catch((err) => {
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  async function getDataFinishTraning() {
    await getUsersPlanningFinishTraining()
      .then((res) => {
        setTrainingFinish(res);
      })
      .catch((err) => {
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  async function getData() {
    await getUsersPlanningToTraining()
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  async function getDataBoot() {
    await getUsersBoot()
      .then((res) => {
        setDataInitialBoot(res);
      })
      .catch((err) => {
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  async function getDataBootTraining() {
    await getUsersBootToTraining()
      .then((res) => {
        setDataBoot(res);
      })
      .catch((err) => {
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  const DescriptionItem = ({ title }) => (
    <div
      className="site-description-item-profile-wrapper"
      style={{ fontFamily: FONT, fontSize: 16 }}
    >
      <b>{title}</b>
    </div>
  );

  function hasBackSquad(exercises) {
    return (
      exercises.filter((item) => item.planningType == "BACK_SQUAD").length > 0
    );
  }

  function getTranslationType(exercise) {
    var name =
      exercise.planningType == "ADDITIONAL_EXERCISE"
        ? exercise.additionalName
        : exercise.planningType == "NEW_EXERCISE"
        ? exercise.nameCvNew
        : translationsTypes[exercise.planningType];
    return name;
  }
  function getTranslationTypeBoot(exercise) {
    return translationsTypes[exercise] ?? exercise;
  }
  const Exercises = ({ exercises }) => {
    const last = exercises[0]?.allTrainings.slice(-1);

    const load = exercises[0]?.trainings[0]?.load ?? last[0]?.load ?? 1;

    return (
      <div
        style={{
          maxHeight: 120,
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        {exercises.map((exercise, index) => (
          <Col style={{ minWidth: 450 }}>
            <Row key={index}>
              {" "}
              <Col span={8}>
                <DescriptionItem title={getTranslationType(exercise)} />
              </Col>
              <Col span={3}>
                <DescriptionItem title={exercise[`load${load}`] + "kg"} />
              </Col>
              <Col span={5}>
                <DescriptionItem
                  title={
                    exercise[`vmp${load}`] != null
                      ? exercise[`vmp${load}`] + " - VMP"
                      : ""
                  }
                />
              </Col>
              <Col span={8}>
                <DescriptionItem
                  title={
                    exercise[`rm${load}`] != null
                      ? exercise.planningType == "BARBELL_JUMP"
                        ? exercise[`rm${load}`] + " - ALT(CM)"
                        : exercise[`rm${load}`] + " - %1RM"
                      : ""
                  }
                />
              </Col>
            </Row>
          </Col>
        ))}
      </div>
    );
  };
  function getTagBoot(item) {
    const typeTrainingA = [
      "GOBLET_SQUAT",
      "HANG_CLEAN",
      "CONTINUOUS_JUMP",
      "SLDL_2_DUMBBELLS",
      "BENCH_BRIDGE_ONE_LEG",
    ];
    const typeTrainingB = [
      "SQUAT_FREE",
      "HANG_CLEAND_2T",
      "DEPTH_JUMP",
      "DEADLIFT",
      "BENCH_BRIDGE_TWO_LEGS",
    ];
    const typeTrainingC = [
      "FRONT_SQUAT",
      "SNATCH",
      "DROP_JUMP",
      "ROMANIAN_SURVEY",
    ];

    let typeTraining = "A";
    let color = "green";
    if (typeTrainingA.includes(item.typeExercise)) {
      typeTraining = "A";
      color = "green";
    }
    if (typeTrainingB.includes(item.typeExercise)) {
      typeTraining = "B";
      color = "geekblue";
    }
    if (typeTrainingC.includes(item.typeExercise)) {
      typeTraining = "C";
      color = "yellow";
    }
    return (
      <Tag color={color} key={typeTraining}>
        {typeTraining.toUpperCase()}
      </Tag>
    );
  }
  function getTagWeek(week) {
    let typeTraining = "A";
    let color = "green";

    if (week % 2 == 0) {
      typeTraining = "B";
      color = "geekblue";
    }

    return (
      <Tag color={color} key={typeTraining}>
        {typeTraining.toUpperCase()}
      </Tag>
    );
  }
  const ExercisesBoot = ({ training }) => {
    const exercises = [
      {
        step: training.step1,
        load: training.load1,
        exercise: training.exercise1,
        loadExecuted: training.loadExecuted1 ?? "X",
        typeExercise: training.typeExercise1,
        week: training.week1,
      },
      {
        step: training.step2,
        load: training.load2,
        exercise: training.exercise2,
        loadExecuted: training.loadExecuted2 ?? "X",
        typeExercise: training.typeExercise2,
        week: training.week2,
      },
      {
        step: training.step3,
        load: training.load3,
        exercise: training.exercise3,
        loadExecuted: training.loadExecuted3 ?? "X",
        typeExercise: training.typeExercise3,
        week: training.week3,
      },
      {
        step: training.step4,
        load: training.load4,
        exercise: training.exercise4,
        loadExecuted: training.loadExecuted4 ?? "X",
        typeExercise: training.typeExercise4,
        week: training.week4,
      },
    ];
    return (
      <div>
        {exercises.map((item, index) => (
          <Col style={{ minWidth: 500 }}>
            <Row key={index}>
              {" "}
              <Col span={10}>
                <DescriptionItem
                  title={getTranslationTypeBoot(item.exercise)}
                />
              </Col>
              {item.step == 4 && (
                <Col span={5}>
                  <DescriptionItem
                    title={
                      <div>
                        {item.step} - {getTagBoot(item)}
                      </div>
                    }
                  />
                </Col>
              )}
              {item.step != 4 && (
                <Col span={5}>
                  <DescriptionItem title={item.step} />
                </Col>
              )}
              <Col span={5}>
                <DescriptionItem
                  title={
                    item.loadExecuted != "X"
                      ? item.loadExecuted + "Kg"
                      : item.loadExecuted
                  }
                />
              </Col>
              <Col span={4}>
                <DescriptionItem
                  title={
                    <div>
                      {item.load} - {getTagWeek(item.week)}
                    </div>
                  }
                />
              </Col>
            </Row>
          </Col>
        ))}
      </div>
    );
  };
  function getWeek(planningExercise, name) {
    const week = planningExercise[0]?.trainings[0]?.load;
    const type = planningExercise[0]?.trainings[0]?.orderTraining;

    if (week) {
      const typeTraining = type % 2 === 0 ? "B" : "A";
      const color = type % 2 === 0 ? "geekblue" : "green";

      return (
        <div>
          {name} - Semana {week} -{" "}
          <Tag color={color} key={typeTraining}>
            {typeTraining.toUpperCase()}
          </Tag>
        </div>
      );
    }
    return name + " - Cíclo Finalizado";
  }
  function getWeekBoot(name) {
    return name;
  }
  const contentScoreDrop = (
    <div>
      <p>
        Queda ≤ 2% → Excelente – Atleta recuperado e pronto para treinar em alta
        intensidade
      </p>
      <p>
        Queda entre 2% e 5% → Bom – Atleta recuperado, mas pode precisar de
        pequenos ajustes no volume
      </p>
      <p>
        Queda entre 5% e 10% → Moderado – Indica sinais de fadiga; pode ser
        necessário reduzir o volume do treinamento
      </p>
      <p>
        Queda entre 10% e 15% → Ruim – Indica cansaço acumulado; sugere
        necessidade de um treino estabilizador ou ajuste no volume e na
        intensidade do treino
      </p>
      <p>
        Queda superior a 15% → Crítico – Indica risco de overtraining; é
        recomendado descanso completo ou um ajuste profundo na carga de
        treinamento
      </p>
    </div>
  );
  const contentScoreWellBeing = (
    <div>
      <p>
        4,5 a 5,0 → Excelente – Atleta recuperado e pronto para treinar em alta
        intensidade.
      </p>
      <p>
        3,5 a 4,4 → Bom – Atleta recuperado, mas pode precisar de ajustes
        menores na carga de treino.
      </p>
      <p>
        2,5 a 3,4 → Moderado – Indica sinais de fadiga; pode ser necessário
        reduzir a carga ou fazer um treino mais leve.
      </p>
      <p>
        1,5 a 2,4 → Ruim – Indica cansaço acumulado; sugere necessidade de
        descanso ativo ou ajuste significativo na carga.
      </p>
      <p>
        1,0 a 1,4 → Crítico – Indica risco de overtraining; é recomendado
        descanso completo ou um ajuste profundo no ciclo de treino.
      </p>
    </div>
  );
  function getScoreControl(value, showScore) {
    let color = "green";
    if (value < -10) {
      color = "red";
    }
    if (value <= -6 && value >= -10) {
      color = "yellow";
    }

    if (showScore) {
      return (
        <Popover content={contentScoreDrop} title="Valores de Queda">
          <div>
            <Tag color={color}>{value}</Tag>
          </div>
        </Popover>
      );
    } else {
      return (
        <div>
          <Tag color={color}>{value}</Tag>
        </div>
      );
    }
  }
  function getScoreControlWellBeing(value) {
    let color = "green";
    if (value < 1.5) {
      color = "red";
    }
    if (value < 2.5 && value >= 1.5) {
      color = "orange";
    }
    if (value < 3.5 && value >= 2.5) {
      color = "yellow";
    }
    if (value < 4.5 && value >= 3.5) {
      color = "blue";
    }
    return (
      <Popover content={contentScoreWellBeing} title="Interpretação Score">
        <div>
          <Tag color={color}>{value}</Tag>
        </div>
      </Popover>
    );
  }
  const CardTrainings = ({ usersTrainings }) => {
    return usersTrainings.map((user) => {
      const [formUser] = Form.useForm();
      const planExercise = user.planningTrainings.filter(
        (p) => p.planningType == "BACK_SQUAD"
      );
      let isOpen = false;
      if (planExercise.length > 0) {
        const exercise = planExercise[0].trainings;
        if (exercise.length > 0) {
          isOpen = exercise[0].forceOpen;
          formUser.setFieldValue("vmp1", exercise[0].vmp1);
          formUser.setFieldValue("vmp2", exercise[0].vmp2);
          formUser.setFieldValue("vmp3", exercise[0].vmp3);
          formUser.setFieldValue("vmp4", exercise[0].vmp4);
        }
      }

      return (
        <Col key={user.id} span={outerWidth < 800 ? 24 : 12}>
          <Card
            style={{
              width: "100%",
              marginBottom: 16,
              minHeight: 400,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            actions={getActionCard(user, formUser)}
          >
            <Meta
              avatar={
                <Avatar
                  src={user?.img}
                  icon={<UserRound size={80} />}
                  size={80}
                />
              }
              title={<div>{getWeek(user.planningTrainings, user.name)}</div>}
              description={
                <div>
                  <Row>
                    <Col span={9}>
                      <DescriptionItem title={"Exercícios"} />
                    </Col>
                  </Row>
                  <Exercises exercises={user.planningTrainings} />

                  {hasBackSquad(user.planningTrainings) && (
                    <>
                      <Row style={{ marginTop: 10 }}>
                        <Col span={24}>
                          <DescriptionItem title={"VMP Agachamento"} />
                        </Col>
                      </Row>
                      <Form form={formUser} layout="vertical">
                        <Row gutter={16}>
                          <Col span={6}>
                            <Form.Item name="vmp1">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() =>
                                  setVmp(formUser, user.planningId, isOpen)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name="vmp2">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() =>
                                  setVmp(formUser, user.planningId, isOpen)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name="vmp3">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() =>
                                  setVmp(formUser, user.planningId, isOpen)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name="vmp4">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() =>
                                  setVmp(formUser, user.planningId, isOpen)
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                  {user.control && (
                    <>
                      <Row>
                        <Col span={4}>
                          <DescriptionItem title={"CMJ PRÉ"} />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem title={"QUEDA %"} />
                        </Col>
                        <Col span={6}>
                          <DescriptionItem title={"SCORE"} />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem title={"CMJ PÓS"} />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem title={"QUEDA %"} />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <DescriptionItem title={user.control.cmjFirst} />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem
                            title={getScoreControl(
                              user.control.cmjFirstDrop,
                              true
                            )}
                          />
                        </Col>
                        <Col span={6}>
                          <DescriptionItem
                            title={getScoreControlWellBeing(
                              user.control.averageWellBeing
                            )}
                          />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem title={user.control.cmjSecond} />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem
                            title={getScoreControl(user.control.cmjSecondDrop)}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              }
            />
          </Card>
        </Col>
      );
    });
  };
  const CardTrainingsFinish = ({ usersTrainings }) => {
    if (usersTrainings.length == 0) {
      return (
        <div style={{ textAlign: "center", width: "100%" }}>
          <ListEmptyState
            subTitle={"Não encontramos alunos com cíclo finalizado"}
          />
        </div>
      );
    }
    return usersTrainings.map((user) => {
      const reviewDate = moment(user.lastReview.reviewDate)
      const planExercise = user.planningTrainings;
      let lastTraining = "";
      if (planExercise.length > 0) {
        const exercise = planExercise[0].allTrainings.slice(-1);
        if (exercise.length > 0) {
          lastTraining = moment(exercise[0].updatedAt);
        }
      }
      let isNewPlan = false;
      if (
        lastTraining != "" &&
        (lastTraining.isBefore(reviewDate) ||
        lastTraining?.format("DD/MM/YYYY") == reviewDate?.format("DD/MM/YYYY"))
      ) {
        isNewPlan = true;
      }
      lastTraining = lastTraining?.format("DD/MM/YYYY");
      return (
        <>
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <Space>
                {isNewPlan && (
                  <Button
                    style={{
                      fontFamily: FONT,
                      background: "#1677ff",
                    }}
                    type="primary"
                    onClick={() => {
                      setUserTrainer(user);
                      setOpenPlaning(true);
                    }}
                    icon={
                      <Plus
                        size={18}
                        style={{ marginBottom: "-3px", marginRight: "7px" }}
                      />
                    }
                  >
                    Nova Planificação
                  </Button>
                )}
                <Button
                  style={{
                    fontFamily: FONT,
                    background: "#1677ff",
                  }}
                  type="primary"
                  onClick={() => {
                    setUserTrainer(user);

                    setOpenReview({
                      open: true,
                      edit: isNewPlan ? true : false,
                    });
                  }}
                  icon={
                    <Plus
                      size={18}
                      style={{ marginBottom: "-3px", marginRight: "7px" }}
                    />
                  }
                >
                  {isNewPlan ? "Editar Avaliação" : "Nova Avaliação"}
                </Button>
              </Space>
            </Col>
          </Row>
          <Col key={user.id} span={24}>
            <Card
              style={{
                width: "100%",
                marginBottom: 16,
                minHeight: 400,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              actions={getActionCardFinish(user)}
            >
              <Meta
                avatar={
                  <Avatar
                    src={user?.img}
                    icon={<UserRound size={80} />}
                    size={80}
                  />
                }
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {getWeek(user.planningTrainings, user.name)}
                  </div>
                }
                description={
                  <div>
                    <Row>
                      <Col span={24}>
                        <div style={{ display: "flex", fontFamily: FONT }}>
                          <DescriptionItem title={"Última Avaliação"} /> {": "}
                          {moment(user.lastReview.reviewDate).format(
                            "DD/MM/YYYY"
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div style={{ display: "flex", fontFamily: FONT }}>
                          <DescriptionItem title={"Último Treino"} /> {": "}
                          {lastTraining}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={9}>
                        <DescriptionItem title={"Exercícios"} />
                      </Col>
                    </Row>
                    <Exercises exercises={user.planningTrainings} />
                  </div>
                }
              />
            </Card>
          </Col>
        </>
      );
    });
  };

  const CardTrainingsBoot = ({ usersTrainings }) => {
    return usersTrainings.map((user) => {
      return (
        <Col key={user.id} span={outerWidth < 800 ? 24 : 12}>
          <Card
            style={{
              width: "100%",
              marginBottom: 16,
              minHeight: 360,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            actions={getActionCardBoot(user)}
          >
            <Meta
              avatar={
                <Avatar
                  src={user?.img}
                  icon={<UserRound size={80} />}
                  size={80}
                />
              }
              title={<div>{getWeekBoot(user.name)}</div>}
              description={
                <div
                  style={{
                    overflowY: "auto",
                    overflowX: "auto",
                  }}
                >
                  <Col style={{ minWidth: 500 }}>
                    <Row>
                      <Col span={9}>
                        <DescriptionItem title={"Exercícios"} />
                      </Col>
                      <Col span={5}>
                        <DescriptionItem title={"Fase"} />
                      </Col>
                      <Col span={4}>
                        <DescriptionItem title={"Carga"} />
                      </Col>
                      <Col span={4}>
                        <DescriptionItem title={"Semanas"} />
                      </Col>
                    </Row>
                  </Col>
                  <ExercisesBoot training={user.training} />
                  {user.control && (
                    <>
                      <Row>
                        <Col span={4}>
                          <DescriptionItem title={"CMJ PRÉ"} />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem title={"QUEDA %"} />
                        </Col>
                        <Col span={6}>
                          <DescriptionItem title={"SCORE"} />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem title={"CMJ PÓS"} />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem title={"QUEDA %"} />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <DescriptionItem title={user.control.cmjFirst} />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem
                            title={getScoreControl(
                              user.control.cmjFirstDrop,
                              true
                            )}
                          />
                        </Col>
                        <Col span={6}>
                          <DescriptionItem
                            title={getScoreControlWellBeing(
                              user.control.averageWellBeing
                            )}
                          />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem title={user.control.cmjSecond} />
                        </Col>
                        <Col span={4}>
                          <DescriptionItem
                            title={getScoreControl(user.control.cmjSecondDrop)}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              }
            />
          </Card>
        </Col>
      );
    });
  };
  const formatTime = (date) => {
    if (!date) return "Nunca atualizado";
    return new Intl.DateTimeFormat("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
  };
  const showDrawerDetail = () => {
    setOpenDetailItem(true);
  };
  function detail(row) {
    setExercise(row);
    showDrawerDetail();
  }
  const onCloseDetailItem = () => {
    setOpenDetailItem(false);
    setExercise(null);
  };
  const showDrawerTrainingFinish = () => {
    setOpenTrainingFinish(true);
  };

  function saveTrainingUser(user, formUser) {
    if (loading) {
      return;
    }
    if (user.planningTrainings[0].trainings.length == 0) {
      notificationInfo("Aluno com cíclo finalizado");
      return;
    }
    setLoading(true);

    const obj = {
      isDone: true,
      planningId: [user.planningId],
      vmp1: formUser.getFieldValue("vmp1"),
      vmp2: formUser.getFieldValue("vmp2"),
      vmp3: formUser.getFieldValue("vmp3"),
      vmp4: formUser.getFieldValue("vmp4"),
    };
    saveTraining(obj)
      .then(() => {
        notificationSucessMsg("Treino finalizado com sucesso");
        refreshData();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  function getActionCard(user, form) {
    if (hasBackSquad(user.planningTrainings)) {
      return [
        <div
          onClick={() => {
            openAnotation(user);
          }}
        >
          <NotepadText />
        </div>,
        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenChart(true);
          }}
        >
          <ChartColumnStacked />
        </div>,

        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenDetail(true);
          }}
        >
          <Edit />
        </div>,
        <div onClick={() => setVmp(form, user.planningId, false, true)}>
          <Trash2 />
        </div>,
        <div onClick={() => saveTrainingUser(user, form)}>
          <Check />
        </div>,
      ];
    } else {
      return [
        <div
          onClick={() => {
            openAnotation(user);
          }}
        >
          <NotepadText />
        </div>,
        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenDetail(true);
          }}
        >
          <Edit />
        </div>,
        <div onClick={() => setVmp(form, user.planningId, false, true)}>
          <Trash2 />
        </div>,
        <div onClick={() => saveTrainingUser(user, form)}>
          <Check />
        </div>,
      ];
    }
  }
  function getActionCardFinish(user) {
    if (hasBackSquad(user.planningTrainings)) {
      return [
        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenChartFinish(true);
          }}
        >
          <ChartColumnStacked />
        </div>,

        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenDetail(true);
          }}
        >
          <Edit />
        </div>,
      ];
    } else {
      return [];
    }
  }
  function getActionCardBoot(user) {
    const steps = [
      user.training.step1,
      user.training.step2,
      user.training.step3,
      user.training.step4,
    ];

    const isStep4Active = steps.some((step) => step === 4);
    const options = [
      <div
        onClick={() => {
          openAnotationBoot(user);
        }}
      >
        <NotepadText />
      </div>,
      <div
        onClick={() => {
          setOpenProgress(true);
          setUserTrainerBoot(user);
        }}
      >
        <ChartColumnStacked />
      </div>,
      isStep4Active && (
        <div
          onClick={() => {
            setOpenChangeTraining(true);
            setUserTrainerBoot(user);
          }}
        >
          <Edit />
        </div>
      ),
      <div onClick={() => closeTreiningBoot(user)}>
        <Trash2 />
      </div>,
      <div onClick={() => saveTrainingUserBoot(user)}>
        <Check />
      </div>,
    ];
    return options.filter((item) => item != false);
  }
  function setVmp(formUser, planningId, isOpen, forceClose) {
    setLoading(true);
    const obj = {
      isDone: false,
      planningId: [planningId],
      vmp1: formUser.getFieldValue("vmp1"),
      vmp2: formUser.getFieldValue("vmp2"),
      vmp3: formUser.getFieldValue("vmp3"),
      vmp4: formUser.getFieldValue("vmp4"),
      isOpen: isOpen,
      forceClose: forceClose,
    };
    saveTraining(obj)
      .then(() => {
        refreshData();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  function updateList() {
    const idsToRemove = new Set(data.map((item) => item.id));
    const idsFinishTraining = new Set(trainingFinish.map((item) => item.id));
    const idsToRemoveBoot = new Set(dataBoot.map((item) => item.id));

    const result = dataPlanning.filter(
      (item) => !idsToRemove.has(item.id) && !idsFinishTraining.has(item.id)
    );

    const resultBoot = dataInitialBoot.filter(
      (item) => !idsToRemoveBoot.has(item.id)
    );
    result.push(...resultBoot);

    if (result.length == 0) {
      notificationInfo(
        "Todos os alunos disponíveis estão no painel ou com cíclos finalizados"
      );
      return;
    }

    const resultOrder = result.sort((a, b) => a.name.localeCompare(b.name));
    openTraing(resultOrder);
  }

  function openTraing(result) {
    confirm({
      title: (
        <div>
          <CircleAlert size={22} style={{ marginBottom: "-5px" }} />
          <span style={{ marginLeft: "10px", fontFamily: FONT }}>
            Qual Aluno ?
          </span>
          <div style={{ marginTop: "10px" }}>
            <Form form={formOpenUser} layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="userOpen">
                    <Select
                      mode="multiple"
                      showSearch
                      optionFilterProp="children"
                    >
                      {result.map((item) => {
                        return <Option value={item.id}>{item.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="typeOpen">
                    <Radio.Group
                      options={[
                        { label: "Último Treino", value: "LAST" },
                        { label: "Novo Treino", value: "NEW" },
                      ]}
                      defaultValue={"NEW"}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      ),
      icon: null,
      okText: "Selecionar",
      cancelText: "Cancelar",
      onOk() {
        setLoading(true);

        const planIdFrom = formOpenUser.getFieldValue("userOpen");
        const usersPlan = [];
        const usersBoot = [];
        planIdFrom?.forEach((itemPlan) => {
          const isUserPlan = dataPlanning.filter((d) => d.id == itemPlan);
          const isUserBoot = dataInitialBoot.filter((d) => d.id == itemPlan);
          if (isUserPlan.length > 0) {
            usersPlan.push(isUserPlan[0].lastPlanId);
          }
          if (isUserBoot.length > 0) {
            usersBoot.push(itemPlan);
          }
        });

        if (usersPlan?.length == 0 && usersBoot.length == 0) {
          notificationInfo("É nescessário informar o aluno");
          setLoading(false);
          return;
        }

        if (usersPlan.length > 0) {
          const typeOpen = formOpenUser.getFieldValue("typeOpen");

          const obj = {
            isDone: false,
            isOpen: true,
            planningId: usersPlan,
            typeOpen: typeOpen ?? "NEW",
          };
          saveTraining(obj)
            .then(() => {
              formOpenUser.resetFields();
              refreshData();
            })
            .catch((err) => {
              formOpenUser.resetFields();
              setLoading(false);
              notificationErroInternal(
                "Aconteceu um erro inesperado, por favor, tente mais tarde."
              );
            });
        }

        if (usersBoot.length > 0) {
          const typeOpen = formOpenUser.getFieldValue("typeOpen");
          const obj = {
            users: usersBoot,
            typeOpen: typeOpen ?? "NEW",
          };
          openTrainingBoot(obj)
            .then(() => {
              formOpenUser.resetFields();
              refreshData();
            })
            .catch((err) => {
              formOpenUser.resetFields();
              setLoading(false);
              notificationErroInternal(
                "Aconteceu um erro inesperado, por favor, tente mais tarde."
              );
            });
          setLoading(false);
          formOpenUser.resetFields();
        }
      },
      onCancel() {
        formOpenUser.resetFields();
      },
    });
  }

  function openAnotationBoot(user) {
    const annotation = user.training.annotation ?? "";
    formAnnotation.setFieldValue("userAnnotation", annotation);
    confirm({
      title: (
        <div>
          <CircleAlert size={22} style={{ marginBottom: "-5px" }} />
          <span style={{ marginLeft: "10px", fontFamily: FONT }}>
            Anotações do Treino
          </span>
          <div style={{ marginTop: "10px" }}>
            <Form form={formAnnotation} layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="userAnnotation">
                    <TextArea rows={8} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      ),
      icon: null,
      okText: "Salvar",
      cancelText: "Cancelar",
      onOk() {
        const obj = {
          trainingId: user.training.id,
          annotation: formAnnotation.getFieldValue("userAnnotation"),
        };
        saveAnnotationBoot(obj)
          .then(() => {
            refreshData();
          })
          .catch((err) => {
            setLoading(false);
            formAnnotation.resetFields();
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      },
      onCancel() {
        formAnnotation.resetFields();
      },
    });
  }
  function openAnotation(user) {
    const planExercise = user.planningTrainings.filter(
      (p) => p.planningType == "BACK_SQUAD"
    );
    let annotation = "";
    if (planExercise.length > 0) {
      const exercise = planExercise[0].trainings;
      if (exercise.length > 0) {
        annotation = exercise[0].annotation;
      }
    }

    formAnnotation.setFieldValue("userAnnotation", annotation);
    confirm({
      title: (
        <div>
          <CircleAlert size={22} style={{ marginBottom: "-5px" }} />
          <span style={{ marginLeft: "10px", fontFamily: FONT }}>
            Anotações do Treino
          </span>
          <div style={{ marginTop: "10px" }}>
            <Form form={formAnnotation} layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="userAnnotation">
                    <TextArea rows={8} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      ),
      icon: null,
      okText: "Salvar",
      cancelText: "Cancelar",
      onOk() {
        const obj = {
          planningId: user.planningId,
          annotation: formAnnotation.getFieldValue("userAnnotation"),
        };
        saveAnnotation(obj)
          .then(() => {
            refreshData();
          })
          .catch((err) => {
            setLoading(false);
            formAnnotation.resetFields();
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      },
      onCancel() {
        formAnnotation.resetFields();
      },
    });
  }
  function saveTrainingUserBoot(user) {
    formDoneBoot.setFieldValue("loadExecuted1", user.training.loadExecuted1);
    formDoneBoot.setFieldValue("loadExecuted2", user.training.loadExecuted2);
    formDoneBoot.setFieldValue("loadExecuted3", user.training.loadExecuted3);
    formDoneBoot.setFieldValue("loadExecuted4", user.training.loadExecuted4);
    const allStep = [
      user.training.step4,
      user.training.step4,
      user.training.step4,
      user.training.step4,
    ].filter((item) => item < 4);
    confirm({
      title: (
        <div>
          <CircleAlert size={22} style={{ marginBottom: "-5px" }} />
          <span style={{ marginLeft: "10px", fontFamily: FONT }}>
            Finalização do Treino
          </span>
          <div style={{ marginTop: "10px" }}>
            <Form form={formDoneBoot} layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  {allStep.length > 0 && (
                    <Form.Item
                      name="nextStep"
                      label={
                        <span style={{ fontFamily: FONT }}>
                          Progredir Treino
                        </span>
                      }
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                      >
                        {user.training.step1 < 4 && (
                          <Option value={user.training.exercise1}>
                            {getTranslationTypeBoot(
                              user.training.typeExercise1
                            )}
                          </Option>
                        )}
                        {user.training.step2 < 4 && (
                          <Option value={user.training.exercise2}>
                            {getTranslationTypeBoot(
                              user.training.typeExercise2
                            )}
                          </Option>
                        )}
                        {user.training.step3 < 4 && (
                          <Option value={user.training.exercise3}>
                            {getTranslationTypeBoot(
                              user.training.typeExercise3
                            )}
                          </Option>
                        )}
                        {user.training.step3 < 4 && (
                          <Option value={user.training.exercise4}>
                            {getTranslationTypeBoot(
                              user.training.typeExercise4
                            )}
                          </Option>
                        )}
                      </Select>
                    </Form.Item>
                  )}
                  {user.training.step1 == 4 && (
                    <Form.Item
                      name="loadExecuted1"
                      label={
                        <span style={{ fontFamily: FONT }}>
                          {getTranslationTypeBoot(user.training.typeExercise1)}
                        </span>
                      }
                    >
                      <InputNumber
                        decimalSeparator=","
                        style={{ fontFamily: FONT, width: "100%" }}
                      />
                    </Form.Item>
                  )}
                  {user.training.step2 == 4 && (
                    <Form.Item
                      name="loadExecuted2"
                      label={
                        <span style={{ fontFamily: FONT }}>
                          {getTranslationTypeBoot(user.training.typeExercise2)}
                        </span>
                      }
                    >
                      <InputNumber
                        decimalSeparator=","
                        style={{ fontFamily: FONT, width: "100%" }}
                      />
                    </Form.Item>
                  )}
                  {user.training.step3 == 4 && (
                    <Form.Item
                      name="loadExecuted3"
                      label={
                        <span style={{ fontFamily: FONT }}>
                          {getTranslationTypeBoot(user.training.typeExercise3)}
                        </span>
                      }
                    >
                      <InputNumber
                        decimalSeparator=","
                        style={{ fontFamily: FONT, width: "100%" }}
                      />
                    </Form.Item>
                  )}
                  {user.training.step4 == 4 && (
                    <Form.Item
                      name="loadExecuted4"
                      label={
                        <span style={{ fontFamily: FONT }}>
                          {getTranslationTypeBoot(user.training.typeExercise4)}
                        </span>
                      }
                    >
                      <InputNumber
                        decimalSeparator=","
                        style={{ fontFamily: FONT, width: "100%" }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="doneBoot"
                    label={
                      <span style={{ fontFamily: FONT }}>
                        Finalizar Iniciação
                      </span>
                    }
                  >
                    <Radio.Group
                      options={[
                        { label: "Manter", value: false },
                        { label: "Avançar", value: true },
                      ]}
                      defaultValue={false}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      ),
      icon: null,
      okText: "Salvar",
      cancelText: "Cancelar",
      onOk() {
        const obj = {
          trainingId: user.training.id,
          loadExecuted1: formDoneBoot.getFieldValue("loadExecuted1"),
          loadExecuted2: formDoneBoot.getFieldValue("loadExecuted2"),
          loadExecuted3: formDoneBoot.getFieldValue("loadExecuted3"),
          loadExecuted4: formDoneBoot.getFieldValue("loadExecuted4"),
          doneBoot: formDoneBoot.getFieldValue("doneBoot") ?? false,
          nextStep: formDoneBoot.getFieldValue("nextStep") ?? [],
        };

        saveTrainingBoot(obj)
          .then(() => {
            formDoneBoot.resetFields();

            refreshData();
          })
          .catch((err) => {
            setLoading(false);
            formDoneBoot.resetFields();
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      },
      onCancel() {
        formDoneBoot.resetFields();
      },
    });
  }
  function closeTreiningBoot(user) {
    setLoading(true);

    closeTraining(user.training.id)
      .then(() => {
        refreshData();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  console.log(userTrainer);
  return (
    <div className="container-managerial">
      <Row gutter={24} style={{ padding: "10px" }}>
        <Space>
          <Button
            style={{
              fontFamily: FONT,
              background: "#09ac9c",
            }}
            type="primary"
            icon={
              <RotateCw
                size={18}
                style={{ marginBottom: "-3px", marginRight: "7px" }}
              />
            }
            onClick={() => {
              refreshData();
            }}
          >
            Atualizar
          </Button>
          <Button
            style={{
              fontFamily: FONT,
            }}
            type="primary"
            icon={
              <Plus
                size={18}
                style={{ marginBottom: "-3px", marginRight: "7px" }}
              />
            }
            onClick={updateList}
          >
            Abrir Treino
          </Button>
          <Badge count={trainingFinish.length} overflowCount={10}>
            <Button
              style={{
                fontFamily: FONT,
                background: "#fcad03",
              }}
              type="primary"
              onClick={showDrawerTrainingFinish}
            >
              Finalizados
            </Button>
          </Badge>
        </Space>
      </Row>
      <Row>
        <div style={{ fontFamily: FONT, fontSize: 12, marginBottom: 15 }}>
          Última atualização: {formatTime(lastUpdated)}{" "}
        </div>
      </Row>
      <Spin spinning={loading}>
        {data.length > 0 && (
          <Row gutter={16}>
            <CardTrainings usersTrainings={data} />
          </Row>
        )}
        {dataBoot.length > 0 && (
          <>
            <Divider />
            <Typography.Title
              level={5}
              style={{ margin: 0, textAlign: "center", color: "#979993" }}
            >
              Alunos em Iniciação
            </Typography.Title>
            <Divider />

            <Row gutter={16}>
              <CardTrainingsBoot usersTrainings={dataBoot} />
            </Row>
          </>
        )}
        {dataBoot.length == 0 && data.length == 0 && (
          <Row gutter={16}>
            <div style={{ textAlign: "center", width: "100%" }}>
              <ListEmptyState subTitle={"A sessão de treino está vazia"} />
            </div>
          </Row>
        )}
      </Spin>

      <DrawerApp
        open={openDetail}
        onClose={onCloseDetail}
        title={"Detalhe do Ciclo"}
        body={""}
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openDetail}
        onClose={onCloseDetail}
        title={"Detalhe do Ciclo"}
        body={
          <PlanningExercises
            isOnlyView={true}
            exercises={
              userTrainer != null
                ? userTrainer.planningTrainings.map((item) => {
                    return {
                      ...item,
                      type: item.planningType,
                      cycle: item.cycleName?.replace(/\D/g, ""),
                      detail: detail,
                    };
                  })
                : []
            }
          />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />

      <DrawerApp
        open={openDetailItem}
        onClose={onCloseDetailItem}
        title={"Detalhe do Exercicio"}
        body={
          <FormNewUserPlanningExercise
            onClose={onCloseDetailItem}
            user={userTrainer}
            exercise={exercise}
          />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openTrainingFinish}
        onClose={onCloseFinishTraining}
        title={"Cíclos Finalizados"}
        body={<CardTrainingsFinish usersTrainings={trainingFinish} />}
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "45%"}
      />

      <DrawerApp
        open={openChart}
        onClose={onCloseChart}
        title={"Gráfico de Evolução"}
        body={<ManagerialChart onClose={onCloseChart} user={userTrainer} />}
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openChartFinish}
        onClose={onCloseChartFinish}
        title={"Gráfico de Evolução"}
        body={
          <ManagerialChart onClose={onCloseChartFinish} user={userTrainer} />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openReview.open}
        onClose={onCloseReview}
        title={"Nova Avaliação"}
        body={
          openReview.edit ? (
            <FormNewUserReview
              user={userTrainer}
              showSprints={false}
              editId={userTrainer.lastReview?.id}
              editRow={userTrainer?.lastReview}
              onClose={onCloseReview}
            />
          ) : (
            <FormNewUserReview
              user={userTrainer}
              showSprints={true}
              lastReview={userTrainer?.lastReview}
              onClose={onCloseReview}
            />
          )
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />

      <DrawerApp
        open={openPlaning}
        onClose={onClosePlaning}
        title={"Nova Planificação"}
        body={
          <FormNewUserPlanning onClose={onClosePlaning} user={userTrainer} />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openDetailBoot}
        onClose={onCloseDetailBoot}
        title={"Ciclo de Treino"}
        body={<FormTrainningBoot />}
        width={outerWidth < 501 ? "100%" : "30%"}
        isOnlyView={true}
      />

      <DrawerApp
        open={openProgress}
        onClose={onCloseProgress}
        title={"Progresso"}
        body={<TimelineBoot user={userTrainerBoot} />}
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "50%"}
        extra={
          <Button
            style={{
              fontFamily: FONT,
              background: "#14B8A6",
              color: "#ffffff",
            }}
            onClick={() => setOpenDetailBoot(true)}
          >
            Ciclo de Treino
          </Button>
        }
      />
      <DrawerApp
        open={openChangeTraining}
        onClose={onCloseChangeTraining}
        title={"Alterar Treino & Carga"}
        body={
          <ChangeBoot
            user={userTrainerBoot}
            onCloseDrawer={onCloseChangeTraining}
          />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "50%"}
      />
    </div>
  );
};

export default Managerial;
