import { Avatar, Space, Tooltip } from "antd";
import { FONT } from "constants/index";
import {
  ArrowDownLeft,
  ArrowDownRight,
  Award,
  ChartColumnStacked,
  ClipboardPen,
  Info,
  SquareChartGantt,
  Trash2,
  Trophy,
  UserRound,
} from "lucide-react";
import moment from "moment";

export const ColumnsUser = () => {
  const table = [
    {
      title: "Foto",
      dataIndex: "image",
      width: "15%",
      render: (text, row) => {
        return (
          <Avatar src={row.img} icon={<UserRound size={50} />} size={50} />
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      textWrap: "word-break",
      ellipsis: true,
      width: "15%",
      render: (text, row) => {
        if (row.isBoot == true) {
          return (
            <div>
              {text}
              <Tooltip style={{ fontFamily: FONT }} title="Aluno em Iniciação">
                <Info
                  color="#f7e96d"
                  style={{
                    marginBottom: "-6px",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          );
        }
        return text;
      },
    },
  ];

  const tableDefault = [
    {
      title: "E-mail",
      dataIndex: "email",
      textWrap: "word-break",
      width: "25%",

      ellipsis: true,
    },
    {
      title: "Última Avaliação",
      dataIndex: "reviewDate",
      textWrap: "word-break",
      width: "15%",
      ellipsis: true,
      render: (text, row) => {
        if (row.lastReview?.reviewDate) {
          return moment(row.lastReview?.reviewDate).format("DD/MM/YYYY");
        } else {
          return "";
        }
      },
    },
  ];
  if (outerWidth > 501) {
    table.push(...tableDefault);
  }

  table.push({
    title: "Ações",
    dataIndex: "action",
    width: "25%",
    render: (text, row) => {
      if (row.isBoot == true) {
        return (
          <Space size="middle">
            <Tooltip style={{ fontFamily: FONT }} title="Avaliações Efetuadas">
              <ChartColumnStacked
                onClick={() => row.comparation(row)}
                size={22}
                style={{ cursor: "pointer", color: "#2980B9" }}
              />
            </Tooltip>
            <Tooltip style={{ fontFamily: FONT }} title="Sprints">
              <Trophy
                onClick={() => row.record(row)}
                size={22}
                style={{ cursor: "pointer", color: "#57534E" }}
              />
            </Tooltip>
          </Space>
        );
      }
      return (
        <Space size="middle">
          <Tooltip style={{ fontFamily: FONT }} title="Nova Avaliação">
            <SquareChartGantt
              onClick={() => row.edit(row)}
              size={22}
              style={{ cursor: "pointer", color: "#09ac9c" }}
            />
          </Tooltip>
          <Tooltip style={{ fontFamily: FONT }} title="Avaliações Efetuadas">
            <ChartColumnStacked
              onClick={() => row.comparation(row)}
              size={22}
              style={{ cursor: "pointer", color: "#2980B9" }}
            />
          </Tooltip>
          <Tooltip
            style={{ fontFamily: FONT }}
            title="Resultado das Avaliações"
          >
            <Award
              onClick={() => row.result(row)}
              size={22}
              style={{ cursor: "pointer", color: "#EAB308" }}
            />
          </Tooltip>
          <Tooltip style={{ fontFamily: FONT }} title="Sprints">
            <Trophy
              onClick={() => row.record(row)}
              size={22}
              style={{ cursor: "pointer", color: "#57534E" }}
            />
          </Tooltip>
        </Space>
      );
    },
  });
  return table;
};

export const ColumnsUserComparation = (dataDrawer) => {
  const table = [
    {
      title: "Data",
      dataIndex: "reviewDate",
      textWrap: "word-break",
      ellipsis: true,
      render: (text, row) => {
        if (text) {
          return moment(text).format("DD/MM/YYYY");
        } else {
          return "";
        }
      },
    },
  ];

  table.push({
    title: "Ações",
    dataIndex: "action",
    render: (text, row) => {
      return (
        <Space size="middle">
          {row.changeActualComp && (
            <Tooltip
              style={{ fontFamily: FONT }}
              title="Selecionar como atual avaliação"
            >
              <ArrowDownLeft
                onClick={() => row.changeActualComp(row, dataDrawer)}
                size={22}
                style={{ cursor: "pointer", color: "#09ac9c" }}
              />
            </Tooltip>
          )}

          {row.changeActualComp && (
            <Tooltip
              style={{ fontFamily: FONT }}
              title="Selecionar como comparação de avaliação"
            >
              <ArrowDownRight
                onClick={() => row.changeLastComp(row, dataDrawer)}
                size={22}
                style={{ cursor: "pointer", color: "#2980B9" }}
              />
            </Tooltip>
          )}
          {row.showDrawer && (
            <Tooltip style={{ fontFamily: FONT }} title="Editar avaliação">
              <ClipboardPen
                onClick={() => row.showDrawer(row)}
                size={22}
                style={{ cursor: "pointer", color: "#57534E" }}
              />
            </Tooltip>
          )}
          {row.delete && (
            <Tooltip style={{ fontFamily: FONT }} title="Deletar avaliação">
              <Trash2
                onClick={() => row.delete(row)}
                size={22}
                style={{ cursor: "pointer", color: "#EC6D62" }}
              />
            </Tooltip>
          )}
        </Space>
      );
    },
  });
  return table;
};
export const ColumnsUserResult = (dataDrawer) => {
  const table = [
    {
      title: "Data",
      dataIndex: "reviewDate",
      textWrap: "word-break",
      ellipsis: true,
      render: (text, row) => {
        if (text) {
          return moment(text).format("DD/MM/YYYY");
        } else {
          return "";
        }
      },
    },
    {
      title: "Pontuação",
      dataIndex: "point",
      textWrap: "word-break",
      ellipsis: true,
    },
    {
      title: "Medalha",
      dataIndex: "point",
      textWrap: "word-break",
      ellipsis: true,
      render: (text, row) => {
        var color = "#14B8A6";
        if (text < 16) {
          color = "#D97706";
        }
        if (text >= 16 && text <= 21) {
          color = "#737373";
        }
        if (text >= 22 && text <= 26) {
          color = "#FDE047";
        }
        return <Award size={25} color={color} />;
      },
    },
  ];

  table.push({
    title: "Ações",
    dataIndex: "action",
    render: (text, row) => {
      return (
        <Space size="middle">
          <Tooltip
            style={{ fontFamily: FONT }}
            title="Selecionar como resultado atual"
          >
            <ArrowDownLeft
              onClick={() => row.changeActual(row, dataDrawer)}
              size={22}
              style={{ cursor: "pointer", color: "#09ac9c" }}
            />
          </Tooltip>
          <Tooltip
            style={{ fontFamily: FONT }}
            title="Selecionar como resultado de comparação"
          >
            <ArrowDownRight
              onClick={() => row.changeLast(row, dataDrawer)}
              size={22}
              style={{ cursor: "pointer", color: "#2980B9" }}
            />
          </Tooltip>
        </Space>
      );
    },
  });
  return table;
};
export const ColumnsUserRecord = () => {
  const table = [
    {
      title: "Data",
      dataIndex: "sprintDate",
      textWrap: "word-break",
      ellipsis: true,
      render: (text, row) => {
        if (text) {
          return moment(text).format("DD/MM/YYYY");
        } else {
          return "";
        }
      },
    },
    {
      title: "Tipo",
      dataIndex: "typeRecord",
      textWrap: "word-break",
      ellipsis: true,
      render: (text, row) => {
        if (text == "COD_SPRINT") {
          return "Cod Sprint";
        } else if (text == "SPRINT_LINEAR") {
          return "Sprint Linear";
        } else {
          return "";
        }
      },
    },
    {
      title: "Resultado",
      dataIndex: "record",
      textWrap: "word-break",
      ellipsis: true,
    },
  ];

  table.push({
    title: "Ações",
    dataIndex: "action",
    render: (text, row) => {
      return (
        <Space size="middle">
          <Tooltip style={{ fontFamily: FONT }} title="Editar resultado">
            <ClipboardPen
              onClick={() => row.edit(row)}
              size={22}
              style={{ cursor: "pointer", color: "#2980B9" }}
            />
          </Tooltip>
          <Tooltip style={{ fontFamily: FONT }} title="Deletar resultado">
            <Trash2
              onClick={() => row.delete(row)}
              size={22}
              style={{ cursor: "pointer", color: "#EC6D62" }}
            />
          </Tooltip>
        </Space>
      );
    },
  });
  return table;
};
